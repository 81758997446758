import { ArrowBackIcon } from '@/Assets/Icons/arrowBack';
import { BASE_URL } from '@/Config/ApiConfig';
import { Button } from 'react-bootstrap';
import { SummaryBlock } from './SummaryBlock';
import { useNavigate } from 'react-router-dom';
import { useAdvancedProgressStore } from '@/pages/ProgressAdvancedPage/store';
import { IBulkReceipt } from '@/types/apiTypes';
import { useWithdraw } from '@/hooks/useWithdraw';
import { useNotify } from '@/hooks/useToast';
import { bulkApi } from '@/services/BulkApi';
import { useWalletFrom } from '@/hooks/useWalletFrom';
import { ADVANCED_PROGRESS_PAGE } from '@/constants/routes.constants';
import { AxiosError } from 'axios';
import { useAdvancedWalletStore } from '@/stores/advanced.store';
import { SelectReceivingAddress } from '../SelectReceivingAddress';
import { SubmitButton } from '../../SubmitButton';
import './styles.css';
import { useState } from 'react';

export type AdvancedTransferSummaryProps = {
  closeConfirm: () => void;
};

export function AdvancedTransferSummary({
  closeConfirm,
}: AdvancedTransferSummaryProps) {
  const [isDisabled, setIsDisabled] = useState(false);

  const [networkFrom, currencyFrom] = useAdvancedWalletStore(s => [
    s.networkFrom,
    s.currencyFrom,
  ]);

  const [receiving, executionType, estimatedFee] = useAdvancedWalletStore(s => [
    s.receiving,
    s.executionType,
    s.estimatedFee,
  ]);

  const [setIsCanceled, setIsWaitingForConfirmation] = useAdvancedProgressStore(
    s => [s.setIsCanceled, s.setIsWaitingForConfirmation]
  );
  const { notify } = useNotify();
  const navigate = useNavigate();
  const { withdraw } = useWithdraw();
  const { walletFrom } = useWalletFrom(networkFrom);

  const createBulkOrder = async () => {
    if (!walletFrom) return;

    setIsDisabled(true);
    let order: IBulkReceipt | undefined;

    try {
      if (!currencyFrom) throw new Error('Currency not found');

      const totalSum = receiving.reduce(
        (acc, el) => acc + parseFloat(el.amount),
        0
      );

      const totalFee = estimatedFee.reduce(
        (acc, { full_fee }) => acc + full_fee,
        0
      );
      order = await bulkApi.createBulkOrder(
        currencyFrom.id,
        (totalSum + totalFee).toString(),
        walletFrom,
        receiving,
        executionType
      );

      if (!order) {
        throw new Error('Failed to create order');
      }

      navigate(`${ADVANCED_PROGRESS_PAGE}/${order.bulkOrderId}`);

      setIsWaitingForConfirmation(true);

      const result = await withdraw({
        sendedAmount: +order.paymentAmount,
        sendedCurrency: currencyFrom,
        network: networkFrom,
        walletReceiver: order.paymentAddress,
      });

      if (!result) {
        setIsCanceled(true);
      }
    } catch (error) {
      if (
        (error as Error).message === 'ACTION_REJECTED' &&
        order &&
        networkFrom
      ) {
        bulkApi.closeBulkOrder(order.bulkOrderId, networkFrom);
      } else if (order && networkFrom) {
        bulkApi.setOrderAsFailed(order.bulkOrderId, networkFrom);
      }
      setIsCanceled(true);
      showSendError(error);
    } finally {
      setIsWaitingForConfirmation(false);
      setIsDisabled(false);
    }
  };

  const showBackendError = (error: AxiosError<{ message: string }>) => {
    const defaultMessage = 'Something went wrong. Please try again later.';
    switch (error.response?.status) {
      case 503:
        return notify({
          title: 'Temporary transfer limit',
          meassage: 'Destination network will become available shortly',
          type: 'error',
        });
      default:
        return notify({
          meassage: error?.response?.data?.message ?? defaultMessage,
          type: 'error',
        });
    }
  };

  const showSendError = (error: unknown) => {
    if (error instanceof AxiosError) {
      return showBackendError(error);
    }
    notify({
      meassage: 'Something went wrong. Please try again later.',
      type: 'error',
    });
  };

  return (
    <div className="w-100">
      <div
        style={{
          maxWidth: '746px',
          width: '100vw',
        }}
        className="formBg mb-0 form-max-width defaultRadius send-advanced-form"
      >
        <div className="transitionHeight formBody text-white align-items-center send-form-min-height">
          <h1 className="advanced-transfer-heading">Your Transfer Summary</h1>
          <div className="advanced-from-input d-flex justify-content-between gap-2">
            <div className="d-flex flex-column gap-2 flex-grow-1 ">
              <div className="bold advanced-summary-header-title">Network</div>
              <div className="w-100 d-flex flex-direction-row align-items-center flex-nowrap">
                <div
                  style={{ justifyContent: 'left' }}
                  className="d-flex border-0 bg-opacity-10 advanced-summary-header-network padding-6 cursor-default pointer-events-none"
                >
                  <div className="d-flex coinBtn border-0 p-1">
                    <img
                      src={BASE_URL + networkFrom?.network_image_url}
                      alt="token"
                      className="ms1 networkIcon"
                    />
                  </div>
                  <div className="mx-1 networkSelectBtn-text">
                    {networkFrom?.name}
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex flex-column gap-2">
              <div className="bold advanced-summary-header-title">Token</div>
              <div className="d-flex my-auto advanced-summary-header-token">
                <div className="w-100 d-flex flex-direction-row align-items-center flex-nowrap">
                  <div className="d-flex align-items-center gap-2 p-0 border-0 bg-opacity-10 padding-6 cursor-default pointer-events-none">
                    <div className="d-flex coinBtn border-0 p-1">
                      <img
                        src={BASE_URL + currencyFrom?.image_url}
                        alt="token"
                        className="networkIcon"
                      />
                    </div>
                    <div className="mx-1 networkSelectBtn-text">
                      {currencyFrom?.symbol}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr className="advanced-transfer-divider" />
          <SelectReceivingAddress label="List of recipients" disabled />
          <hr className="advanced-transfer-divider" />
          <SummaryBlock />
          <div className="d-flex flex-column gap-4">
            <SubmitButton
              title="Create Advanced Transfer"
              networkFrom={networkFrom}
              currencyFrom={currencyFrom}
              isDisabled={isDisabled}
              onSubmit={createBulkOrder}
              isConfirm
            />
            <div className="center">
              <Button
                variant="link"
                onClick={closeConfirm}
                className="d-flex align-items-center backBtn ms-auto me-auto text-white"
              >
                <ArrowBackIcon className="arroBack" />

                <div className="ms-2">Back</div>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
