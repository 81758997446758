import { ethers } from 'ethers';
import { formatUnits, parseUnits } from 'ethers/lib/utils';
import { maxDecimals, maxPlatformDecimals } from '../constants/numbers';
import { AmountBySymbolOptions } from '@/types/interfaces';

export const getDecimals = (val: string) => {
  const dividerIndex = getDecimalDividerIndex(val);
  if (dividerIndex === -1) {
    return 0;
  }
  const divider = val[dividerIndex];
  const decimals = val.split(divider)[1]?.length ?? 0;
  return decimals;
};

export const getMaxDecimals = (fromString: string, valueString: string) => {
  const decimalsFrom = getDecimals(fromString);
  const decimalsValue = getDecimals(valueString);
  const biggerDecimal = Math.max(decimalsFrom, decimalsValue);

  return biggerDecimal;
};

export const getDecimalDividerIndex = (val: string) => {
  if (isNaN(+val)) {
    throw new Error('Value is not number string');
  }

  return val.indexOf('.');
};

export const exponentNumberToString = (x: number) => {
  if (Math.abs(x) < 1) {
    const [num, eString] = x.toString().split('e-');

    const exponentDecimals = parseInt(eString);
    const numDecimals = getDecimals(num);

    if (exponentDecimals) {
      const amountBn = ethers.utils.parseUnits(num, numDecimals);
      const amount = ethers.utils.formatUnits(
        amountBn,
        exponentDecimals + numDecimals
      );

      return amount;
    }

    return num;
  } else {
    const [num, eString] = x.toString().split('e+');

    const exponentDecimals = parseInt(eString);

    if (exponentDecimals) {
      const amountBn = ethers.utils.parseUnits(num, exponentDecimals);

      return amountBn.toString();
    }

    return num;
  }
};

export const toFixed = (value: number, precision: number) => {
  if (!value) {
    return '0';
  }
  value = Number(value);
  const fixed = value.toFixed(precision + 2);
  const length = fixed.length;
  const splittedByDot = fixed.substring(0, length - 2).split('.');

  if (+splittedByDot[1] === 0) {
    return splittedByDot[0];
  }
  return removeZeroFromEnd(fixed.substring(0, length - 2));
};

const removeZeroFromEnd = (value: string): string => {
  if (!value.endsWith('0')) return value;

  const res = value.slice(0, -1);
  return removeZeroFromEnd(res);
};

export const decimalSlicer = (val: string, decimals = maxDecimals) => {
  const slicedVal = val.slice(0, decimals + 2);
  return slicedVal;
};

export const subtractNumbers = (from: number, value: number) => {
  const fromString = exponentNumberToString(from);
  const valueString = exponentNumberToString(value);
  const biggerDecimal = getMaxDecimals(fromString, valueString);

  return subtractNumberStringValue(fromString, valueString, biggerDecimal);
};

export const subtractNumberStringValue = (
  from: string,
  value: string,
  decimal: number
): string => {
  if (decimal > maxDecimals) {
    const slicedFrom = decimalSlicer(from);
    const slicedValue = decimalSlicer(value);

    return subtractNumberStringValue(slicedFrom, slicedValue, maxDecimals);
  }

  const fromBn = ethers.utils.parseUnits(from, decimal);
  const valueBn = ethers.utils.parseUnits(value, decimal);

  const subtracted = ethers.utils.formatUnits(fromBn.sub(valueBn), decimal);

  return decimalSlicer(subtracted);
};

export const getMinimalDecimalNumber = (val?: string | number): string => {
  if (!val || +val <= 0) {
    return '0';
  }

  const numberString = exponentNumberToString(+val);

  const decimals = getDecimals(numberString);

  if (+val > 1) {
    const [integer, decimal] = numberString.split('.');
    if (decimal && +decimal > 0) {
      const decStringNum = `0.${decimal.slice(0, 3)}`;
      const val = getMinimalDecimalNumber(decStringNum);
      const result = [integer, val.slice(2)].join('.');

      return result;
    }
    return numberString;
  }

  if (+val > 0.001) {
    return parseLowValue(numberString, decimals, 2);
  }

  return parseLowValue(numberString, decimals, 1);
};

const parseLowValue = (
  numberString: string,
  decimals: number,
  slicedCount = 2
) => {
  const valueBn = parseUnits(numberString, decimals).toString();

  const fill = new Array(valueBn.slice(slicedCount).length).fill('0');
  const minimalBn = [...valueBn.slice(0, slicedCount), ...fill].join('');

  const minimal = formatUnits(minimalBn, decimals);

  return minimal;
};

export const formatAmountBySymbol = (
  value: number,
  symbol: string,
  options: AmountBySymbolOptions = {
    stableCoins: 2,
    eth: 4,
    btc: 6,
    ton: 2,
    sol: 5,
    useCeil: false,
  }
) => {
  const round = options.useCeil
    ? (value: number, decimals: number) =>
        (Math.ceil(value * 10 ** decimals) / 10 ** decimals).toString()
    : toFixed;

  switch (symbol.toUpperCase()) {
    case 'USDT':
    case 'USDC':
      return round(value, options?.stableCoins || 2);
    case 'ETH':
    case 'WETH':
      return round(value, options?.eth || 4);
    case 'BTC':
    case 'WBTC':
      return round(value, options?.btc || 6);
    case 'TON':
      return round(value, options?.ton || 2);
    case 'SOL':
      return round(value, options?.sol || 5);
    default:
      return round(value, maxPlatformDecimals);
  }
};

export const roundToMinNonZeroDecimals = (num: number, minDecimals = 2) => {
  if (num <= 0) return '0';

  const decimals = -Math.floor(Math.log10(num) - 1);
  return num.toFixed(decimals < minDecimals ? minDecimals : decimals);
};
