import { Tooltip } from '@/components/tooltips';
import { maxPlatformDecimals } from '@/constants/numbers';
import { AVG_ORDER_EXECUTION_TIME_MS, MINUTE } from '@/constants/time';
import { NetworkTypes } from '@/providers/web3Provider';
import { bulkApi } from '@/services/BulkApi';
import { useStarknetAccount } from '@/starknet/hooks/account';
import { useAdvancedWalletStore } from '@/stores/advanced.store';
import { getPrice, usePriceUpdater } from '@/stores/price_updater.store';
import { toFixed } from '@/utils/numbers';
import { useEffect, useMemo } from 'react';
import { useAccount as useEVMAccount } from 'wagmi';
import { useShallow } from 'zustand/react/shallow';

export function SummaryBlock() {
  const { prices } = usePriceUpdater();
  const [
    receiving,
    currencies,
    estimatedFee,
    currencyFrom,
    executionType,
    executionIntervals,
    networkFrom,
  ] = useAdvancedWalletStore(
    useShallow(s => [
      s.receiving,
      s.currencies,
      s.estimatedFee,
      s.currencyFrom,
      s.executionType,
      s.executionIntervals,
      s.networkFrom,
    ])
  );

  const coinSymbol = useMemo(() => {
    return currencyFrom?.symbol || '';
  }, [currencyFrom?.symbol]);

  const price = useMemo(() => {
    const price = getPrice(coinSymbol, prices);
    return price;
  }, [prices, coinSymbol]);

  const { address: EVMAddress } = useEVMAccount();
  const { address: starknetAddress } = useStarknetAccount();

  const address = useMemo(() => {
    if (networkFrom?.network_type === NetworkTypes.STARKNET) {
      return starknetAddress;
    }

    return EVMAddress;
  }, [networkFrom?.network_type, starknetAddress, EVMAddress]);

  useEffect(() => {
    const timer = setTimeout(() => {
      bulkApi.getEstimatedFee(address, receiving);
    }, 2000);

    return () => {
      clearTimeout(timer);
    };
  }, [address, receiving, currencies]);

  const [
    totalFee,

    totalPlatformFee,
    platformFeeInUSD,
    platformFeeCount,
    samePlatformFee,

    totalDestinationFee,
    destinationFeeInUSD,
    destinationFeeCount,
    sameDestinationFee,
  ] = useMemo(() => {
    let totalFee = 0;

    let platformFee = 0;
    let platformFeeCount = 0;
    const samePlatformFee = estimatedFee.every(
      fee => fee.platform_fee_in_usd === estimatedFee[0].platform_fee_in_usd
    );

    let destinationChainFee = 0;
    let destinationFeeCount = 0;
    const sameDestinationFee = estimatedFee.every(
      fee => fee.currency_out_id === estimatedFee[0].currency_out_id
    );

    for (const fee of estimatedFee) {
      totalFee += fee?.full_fee ?? 0;

      platformFee += fee?.platform_fee ?? 0;
      platformFeeCount++;

      destinationChainFee += fee?.blockchain_fee ?? 0;
      destinationFeeCount++;
    }

    return [
      totalFee,

      platformFee,
      estimatedFee?.[0]?.platform_fee_in_usd ?? 0,
      platformFeeCount,
      samePlatformFee,

      destinationChainFee,
      estimatedFee?.[0]?.blockchain_fee_in_usd ?? 0,
      destinationFeeCount,
      sameDestinationFee,
    ];
  }, [estimatedFee]);

  const totalToSent = useMemo(() => {
    return receiving.reduce((acc, { amount }) => acc + +amount, 0) + totalFee;
  }, [receiving, totalFee]);

  const estimatedTime = useMemo(() => {
    return Math.floor(
      (receiving.length *
        (executionIntervals[executionType] + AVG_ORDER_EXECUTION_TIME_MS)) /
        MINUTE
    );
  }, [executionIntervals, executionType, receiving.length]);

  return (
    <>
      <div className="text-white bold network-label mb-3">Summary</div>
      <div className="row g-2 mb-3">
        <div className="col d-flex flex-column gap-2">
          <span className="advanced-summary-block-label">
            Total Receiving Addresses
          </span>
          <span className="advanced-summary-block">{receiving.length}</span>
        </div>
        <div className="col d-flex flex-column gap-2">
          <span className="advanced-summary-block-label">
            Total Amount to Send
          </span>
          <span className="advanced-summary-block">
            <span className="bold">
              {toFixed(totalToSent, maxPlatformDecimals)} {coinSymbol}
            </span>
            <span className="advanced-summary-block-small">
              ${toFixed(price.usd * totalToSent, 2)}
            </span>
          </span>
        </div>
      </div>
      <div className="row g-2">
        <div className="col d-flex flex-column gap-2">
          <span className="advanced-summary-block-label">Total Fees</span>
          <span className="advanced-summary-block">
            <span className="bold">
              {toFixed(totalFee, maxPlatformDecimals)} {coinSymbol}
            </span>
            <span className="advanced-summary-block-small">
              ${toFixed(price.usd * totalFee, 2)}
            </span>
          </span>
        </div>
        <div className="col d-flex flex-column gap-2">
          <span className="advanced-summary-block-label">Execution Type</span>
          <span className="advanced-summary-block">
            <span className="bold">{executionType}</span>
            <span className="advanced-summary-block-small">
              ~{estimatedTime} min
            </span>
          </span>
        </div>
      </div>
      <div className="overflow-hidden text-secondary transitionHeight d-flex flex-column advanced-receiveBlock infoBlock mt-2">
        <div className="d-flex flex-row justify-content-between font-size-12 align-items-center retrobridge-fee-wrapper">
          RetroBridge Fee:
          <div className="text-light fw-bolder">
            {samePlatformFee && (
              <span className="fw-medium blanco receiveUsdAmount">
                {platformFeeCount} x ${toFixed(platformFeeInUSD, 2)}
              </span>
            )}
            {toFixed(totalPlatformFee, maxPlatformDecimals)} {coinSymbol}
          </div>
        </div>
        <div className="d-flex flex-row justify-content-between font-size-12 align-items-center retrobridge-fee-wrapper">
          Destination Chain Fee:
          <Tooltip variant="dark">
            <span className="text-white">
              Cost of transfer to your addresses in Destination networks
            </span>
          </Tooltip>
          <div className="text-light fw-bolder">
            {sameDestinationFee && (
              <span className="fw-medium blanco receiveUsdAmount">
                {destinationFeeCount} x $
                {toFixed(destinationFeeInUSD, maxPlatformDecimals)}
              </span>
            )}
            {toFixed(totalDestinationFee, maxPlatformDecimals)} {coinSymbol}
          </div>
        </div>
        <div className="d-flex flex-row justify-content-between align-items-center font-size-12 retrobridge-time-wrapper">
          Estimated Time:
          <div className="d-flex align-items-center text-light fw-bolder">
            {estimatedTime} min
            <Tooltip
              variant="dark"
              contentProps={{
                align: 'end',
              }}
            >
              <span className="text-white">
                Timeline for executing bridging orders in sequence
              </span>
            </Tooltip>
          </div>
        </div>
      </div>
    </>
  );
}
