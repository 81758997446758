import searchIcon from '@/Assets/Icons/search.svg';
import { Switch } from '@/components/Switch';
import { INetwork } from '@/types/apiTypes';
import { PopoverContent, PopoverPortal, Root } from '@radix-ui/react-popover';
import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import { Fragment, ReactNode, useMemo, useState } from 'react';
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import { NetworkItem } from './NetworkItem';
import './styles.css';
import { useAdvancedWalletStore } from '@/stores/advanced.store';
import { categorySortPoints } from '../../SendForm/NetworkInput/chooseNetworkModal';

interface AdvancedNetworkSelectProps {
  children?: ReactNode | ((network: INetwork | undefined) => ReactNode);
  variant?: 'from' | 'to';
  value: INetwork | undefined;
  onChange: (network: INetwork | undefined) => void;
  onSwitch?: () => void;
  networks: INetwork[];
  disabled?: boolean;
}

const menuVariants = {
  animate: {
    transition: {
      delayChildren: 0.1,
      staggerChildren: 0.05,
    },
  },
};

export function AdvancedNetworkSelect({
  children,
  variant = 'to',
  value,
  onChange,
  networks,
  disabled,
  onSwitch,
}: AdvancedNetworkSelectProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [search, setSearch] = useState('');
  const [shouldSetSingleNetwork, setShouldSetSingleNetwork] =
    useAdvancedWalletStore(s => [
      s.shouldSetSingleNetwork,
      s.setShouldSetSingleNetwork,
    ]);

  const filteredNetwork: INetwork[] = useMemo(() => {
    if (search) {
      return networks.filter(el =>
        el.name.toLowerCase().startsWith(search.toLowerCase())
      );
    } else {
      return networks;
    }
  }, [networks, search]);

  const networksByCategory = useMemo(() => {
    return filteredNetwork.reduce(
      (prev, cur) => {
        if (cur.category in prev) {
          prev[cur.category].push(cur);
        } else {
          prev[cur.category] = [cur];
        }

        return prev;
      },
      {} as Record<string, INetwork[]>
    );
  }, [filteredNetwork]);

  return (
    <div className={clsx('advanced-network-select', variant)}>
      <Root
        open={isOpen}
        onOpenChange={val => {
          if (disabled) return;
          setIsOpen(val);
          if (!val) {
            setSearch('');
          }
        }}
      >
        <AnimatePresence mode="wait">
          {children instanceof Function ? children?.(value) : children}
          {isOpen && (
            <PopoverPortal forceMount>
              <PopoverContent
                align="end"
                avoidCollisions={false}
                sideOffset={8}
                autoFocus={false}
                onOpenAutoFocus={e => e.preventDefault()}
                asChild
              >
                <motion.div
                  initial={{
                    opacity: 0,
                  }}
                  animate={{
                    opacity: 1,
                  }}
                  exit={{
                    opacity: 0,
                  }}
                  className={clsx('advanced-network-select-menu', variant)}
                >
                  <label className="advanced-network-select-search">
                    <img
                      src={searchIcon}
                      className="advanced-network-select-search-icon"
                      alt=""
                    />
                    <input
                      type="text"
                      value={search}
                      onChange={e => setSearch(e.target.value)}
                      placeholder="Search by name"
                    />
                  </label>
                  {variant === 'to' && (
                    <div className="advanced-network-select-mode">
                      <Switch
                        checked={shouldSetSingleNetwork}
                        onCheckedChange={val => {
                          setShouldSetSingleNetwork(val);
                          onSwitch?.();
                        }}
                      />
                      Single network for all transfers
                    </div>
                  )}
                  <SimpleBar
                    style={{
                      maxHeight: variant === 'to' ? '289px' : '325px',
                      paddingRight: '10px',
                      height: '100%',
                    }}
                  >
                    <motion.div
                      variants={menuVariants}
                      animate="animate"
                      className="advanced-network-select-menu-content"
                    >
                      {Object.keys(networksByCategory)
                        .sort((a, b) => {
                          const aPoints = categorySortPoints(a);
                          const bPoints = categorySortPoints(b);

                          return bPoints - aPoints;
                        })
                        .map(category => (
                          <Fragment key={category}>
                            <motion.div
                              initial={{
                                opacity: 0,
                                filter: 'blur(10px)',
                              }}
                              variants={{
                                animate: {
                                  opacity: 1,
                                  filter: 'blur(0px)',
                                },
                              }}
                              className="advanced-network-select-menu-category"
                            >
                              {category}
                            </motion.div>
                            {networksByCategory[category].map(el => (
                              <NetworkItem
                                variant={variant}
                                network={el}
                                key={el.id}
                                onChange={() => {
                                  onChange(el);
                                }}
                              />
                            ))}
                          </Fragment>
                        ))}
                    </motion.div>
                  </SimpleBar>
                </motion.div>
              </PopoverContent>
            </PopoverPortal>
          )}
        </AnimatePresence>
      </Root>
    </div>
  );
}
