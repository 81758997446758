import { useWallet as useTronWallet } from '@tronweb3/tronwallet-adapter-react-hooks';
import { OptionsProps } from '..';
import { AdapterName } from '@tronweb3/tronwallet-abstract-adapter';
import { WalletConnectWalletName } from '@tronweb3/tronwallet-adapters';
import { TRON_ICONS } from '@/tron/hooks/useConnectedTronIcon';

export function TronOptions({ onOpenChange }: OptionsProps) {
  const { wallets, select, connected } = useTronWallet();

  const handleTronConnect = async (
    adapterName: AdapterName<string>,
    maxRepeats = 1
  ) => {
    if (maxRepeats < 0) {
      return;
    }
    maxRepeats--;
    try {
      switch (adapterName) {
        case WalletConnectWalletName:
          await wallets
            .find(w => w.adapter.name === WalletConnectWalletName)
            ?.adapter.connect();
          select(WalletConnectWalletName);
          return;

        default:
          select(adapterName);
          if (!connected) {
            await wallets
              .find(w => w.adapter.name === adapterName)
              ?.adapter.connect();
            onOpenChange(false);
          }
          return;
      }
    } catch (error) {
      if (
        error instanceof Error &&
        error.message === 'No wallet is selected. Please select a wallet.'
      ) {
        await new Promise(res => setTimeout(res, 10000));
        console.log(adapterName);
        console.log(error.message);
        await handleTronConnect(adapterName, maxRepeats);
      }
    }
  };

  return (
    <div className="connect-wallet--group">
      <div className="connect-wallet--group-heading">Tron wallets</div>
      <div className="connect-wallet--group-grid">
        {wallets.map(wallet => (
          <button
            key={wallet.adapter.name}
            className="connect-wallet--group-item w-100"
            onClick={async () => {
              handleTronConnect(wallet.adapter.name);
            }}
          >
            <div style={{ borderRadius: '50%', backgroundColor: 'white' }}>
              <img
                src={
                  TRON_ICONS[wallet.adapter.name]
                    ? require(
                        `@/Assets/images/wallets/${TRON_ICONS[wallet.adapter.name]}.png`
                      )
                    : wallet.adapter.icon
                }
                style={{
                  height: '24px',
                  width: '24px',
                  borderRadius: '50%',
                }}
                alt={wallet.adapter.name}
              />
            </div>

            {wallet.adapter.name}
          </button>
        ))}
      </div>
    </div>
  );
}
