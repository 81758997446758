import clsx from 'clsx';
import { useMemo } from 'react';
import { CurrencySelect } from '@/pages/SendPage/components/CurrencySelect';
import { useAdvancedWalletStore } from '@/stores/advanced.store';
import './styles.css';

export function TokenChangeInput() {
  const [networkFrom, currencyFrom, setCurrencyFrom, currencies] =
    useAdvancedWalletStore(s => [
      s.networkFrom,
      s.currencyFrom,
      s.setCurrencyFrom,
      s.currencies,
    ]);

  const sourceCurrencies = useMemo(
    () => currencies.filter(c => c.contract.network.id === networkFrom?.id),
    [currencies, networkFrom]
  );

  return (
    <div
      className={clsx(
        'advanced-token-change-input',
        'fillInDialog-token-change'
      )}
    >
      <div className="text-white bold network-label">Token</div>
      <div className="d-flex flex-diections-row py-2">
        <CurrencySelect
          value={currencyFrom}
          onChange={setCurrencyFrom}
          options={sourceCurrencies}
          isInModal={true}
          align="start"
        />
      </div>
    </div>
  );
}
