import { Helmet } from 'react-helmet';
import { AnimatePresence, motion } from 'framer-motion';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useShallow } from 'zustand/react/shallow';
import Container from 'react-bootstrap/Container';
import { Stack } from 'react-bootstrap';
import { api } from '@/services/api';
import { logInGroup } from '@/utils';
import SendBg from '@/Assets/images/send-bg-png.png';
import SendBgSmall from '@/Assets/images/send-bg-small.svg';
import Footer from '@/components/Footer';
import Header from '@/components/Header/header';
import { Loader } from '@/components/Loader';
import useDebounce from '@/hooks/useDebounce';
import { useIsPageLoaded } from '@/hooks/useIsPageLoaded';
import { appStore, useAppStore } from '@/stores/app.store';
import SendForm from './components/SendForm';
import { useWalletTo } from '@/hooks/useWalletTo';
import { useWalletFrom } from '@/hooks/useWalletFrom';
import TransferInfoForm from './components/SendForm/TransferInfo';
import { SendAdvanced } from './components/SendAdvancedForm';
import { AdvancedTransferSummary } from './components/SendAdvancedForm/AdvancedTransferSummary';
import { AppModeSwitch } from './components/AppModeSwitch';
import { AnimateHeightChange } from '@/components/AnimateHeightChange';

import './SendPage.css';

const ANIMATION_DURATION_S = 0.2;

enum FORM_STEP {
  REGULAR,
  REGULAR_CONFIRM,
  ADVANCED,
  ADVANCED_CONFIRM,
}

const SendPage = () => {
  const [formStep, setFormStep] = useState<FORM_STEP>(FORM_STEP.REGULAR);

  const { isAdvancedOpen, shouldDisplaySwitch } = useMemo(() => {
    const isAdvancedOpen =
      formStep === FORM_STEP.ADVANCED ||
      formStep === FORM_STEP.ADVANCED_CONFIRM;

    const shouldDisplaySwitch = [
      FORM_STEP.ADVANCED,
      FORM_STEP.REGULAR,
    ].includes(formStep);

    return { isAdvancedOpen, shouldDisplaySwitch };
  }, [formStep]);

  useEffect(() => {
    const { setIsAdvancedOpen } = appStore.getState();

    setIsAdvancedOpen(isAdvancedOpen);
  }, [isAdvancedOpen]);

  const [setDestinationCurrencies] = useAppStore(s => [
    s.setDestinationCurrencies,
  ]);

  const [networkFrom, networkTo, currencyFrom, currencyTo, amountFrom] =
    useAppStore(
      useShallow(s => [
        s.networkFrom,
        s.networkTo,
        s.currencyFrom,
        s.currencyTo,
        s.amountFrom,
      ])
    );

  const amountFromDebounced = useDebounce(amountFrom, 150);

  const { walletFrom } = useWalletFrom(networkFrom);
  const { walletTo } = useWalletTo();

  const pageLoaded = useIsPageLoaded(SendBg);

  useEffect(() => {
    api.getNetwork();
  }, []);

  useEffect(() => {
    if (networkFrom && networkTo)
      api.getCurrencyPairs(networkFrom.id, networkTo.id);
  }, [networkFrom, networkTo]);

  useEffect(() => {
    if (currencyFrom) setDestinationCurrencies(currencyFrom.pairs);
  }, [currencyFrom, setDestinationCurrencies]);

  useEffect(() => {
    if (currencyFrom && currencyTo && currencyFrom.id !== currencyTo.id)
      api.getLimits(currencyFrom, currencyTo);
  }, [currencyFrom, currencyTo]);

  useEffect(() => {
    if (
      currencyFrom &&
      currencyTo &&
      currencyFrom.id !== currencyTo.id &&
      +amountFromDebounced > 0
    ) {
      api
        .getAmountOut(
          currencyFrom.id,
          currencyTo.id,
          amountFromDebounced,
          walletFrom,
          walletTo
        )
        .catch(e => logInGroup('getAmountOut', e));
    }
  }, [currencyFrom, currencyTo, amountFromDebounced, walletFrom, walletTo]);

  const isOpenChangeTimeout = useRef<NodeJS.Timeout | null>(null);

  const handleSwitchChange = (isChecked: boolean) => {
    if (!isOpenChangeTimeout.current) {
      setFormStep(isChecked ? FORM_STEP.ADVANCED : FORM_STEP.REGULAR);
    }
    if (isOpenChangeTimeout.current) clearTimeout(isOpenChangeTimeout.current);

    isOpenChangeTimeout.current = setTimeout(
      () => {
        isOpenChangeTimeout.current = null;
      },
      ANIMATION_DURATION_S * 1000 * 2
    );
  };

  return (
    <div className="page-background-color">
      <Helmet>
        <title>App | Retro Bridge</title>
      </Helmet>
      <Container
        fluid
        className="send-page-container mainSend  min-vh-100 w-100 d-flex flex-1  justify-content-center flex-column align-items-center page-padding-bottom pt-2 position-relative"
      >
        {pageLoaded ? (
          <>
            <img src={SendBg} alt="" className="send-bg-img" />
            <img src={SendBgSmall} alt="" className="send-bg-img-mobile" />
            <Header />
            <Stack className="mw-100 col-xs-12 col-sm-12 col-md-6 mx-auto col-lg-5 col-xl-4 w-100 flex align-items-center">
              <div className="form-mt">
                {shouldDisplaySwitch && (
                  <AppModeSwitch
                    isAdvancedOpen={isAdvancedOpen}
                    setIsAdvancedOpen={handleSwitchChange}
                  />
                )}
                <motion.div
                  layout
                  animate={{ height: 'auto' }}
                  style={{
                    maxWidth: isAdvancedOpen ? 746 : 474,
                    transition: `${ANIMATION_DURATION_S}s ease-in-out`,
                    overflow: isAdvancedOpen ? 'unset' : 'hidden',
                  }}
                  transition={{
                    duration: ANIMATION_DURATION_S,
                    ease: 'easeInOut',
                  }}
                  className="formBg mb-0 form-max-width send-form-wrapper"
                >
                  <AnimateHeightChange
                    transition={{
                      duration: ANIMATION_DURATION_S,
                      ease: 'easeInOut',
                    }}
                  >
                    <AnimatePresence mode="wait">
                      {formStep === FORM_STEP.REGULAR && (
                        <SendForm
                          openConfirm={() =>
                            setFormStep(FORM_STEP.REGULAR_CONFIRM)
                          }
                        />
                      )}
                      {formStep === FORM_STEP.REGULAR_CONFIRM && (
                        <TransferInfoForm
                          closeConfirm={() => setFormStep(FORM_STEP.REGULAR)}
                        />
                      )}
                      {formStep === FORM_STEP.ADVANCED && (
                        <SendAdvanced
                          openConfirm={() =>
                            setFormStep(FORM_STEP.ADVANCED_CONFIRM)
                          }
                        />
                      )}
                      {formStep === FORM_STEP.ADVANCED_CONFIRM && (
                        <AdvancedTransferSummary
                          closeConfirm={() => setFormStep(FORM_STEP.ADVANCED)}
                        />
                      )}
                    </AnimatePresence>
                  </AnimateHeightChange>
                </motion.div>
              </div>
            </Stack>
            <div className="send-page-footer">
              <Footer />
            </div>
          </>
        ) : (
          <Loader />
        )}
      </Container>
    </div>
  );
};

export default SendPage;
