import { create } from 'zustand';

import { ExecutionType } from '@/types/enums';
import { ICurrency, INetwork } from '@/types/apiTypes';

export const ethSymbols = ['ETH', 'WETH'];
export const btcSymbols = ['BTC', 'WBTC'];
export const MAX_BULK_RECIPIENTS = 50;

export interface AdvancedWalletStore {
  isDirty: boolean;

  networkFrom?: INetwork;
  setNetworkFrom: (network: INetwork) => void;

  currencyFrom?: ICurrency;
  setCurrencyFrom: (currency: ICurrency) => void;

  currencies: ICurrency[];
  setCurrencies: (currencies: AdvancedWalletStore['currencies']) => void;

  receiving: {
    address: string;
    amount: string;
    network?: INetwork['id'];
  }[];
  executionType: ExecutionType;

  isEstimationLoading: boolean;
  setIsEstimationLoading: (value: boolean) => void;
  estimatedFee: {
    currency_out_id: string;
    platform_fee: number;
    platform_fee_in_usd: number;
    blockchain_fee: number;
    blockchain_fee_in_usd: number;
    full_fee: number;
  }[];
  setEstimatedFee: (fees: AdvancedWalletStore['estimatedFee']) => void;

  executionIntervals: Record<ExecutionType, number>;
  setExecutionIntervals: (intervals: Record<ExecutionType, number>) => void;

  shouldSetSingleNetwork: boolean;
  setShouldSetSingleNetwork: (value: boolean) => void;

  addReceiving: (address: string, amount: string, network?: string) => void;
  removeReceiving: (index: number) => void;

  setReceivingData: (
    index: number,
    data: { address: string; amount: string; network?: string }
  ) => void;

  setReceivingAddress: (index: number, address: string) => void;

  setReceivingAmount: (index: number, amount: string) => void;

  setReceivingNetwork: (
    index: number,
    network?: string,
    forAll?: boolean
  ) => void;

  setExecutionType: (type: ExecutionType) => void;

  resetForm: () => void;
}

export const advancedWalletStore = create<AdvancedWalletStore>((set, get) => ({
  isDirty: false,

  networkFrom: undefined,
  setNetworkFrom: network => set({ networkFrom: network }),
  currencyFrom: undefined,
  setCurrencyFrom: currency => set({ currencyFrom: currency }),

  receiving: [],
  executionType: ExecutionType.Sequence,

  currencies: [],
  setCurrencies: currencies => set({ currencies }),

  shouldSetSingleNetwork: false,
  setShouldSetSingleNetwork: (value: boolean) =>
    set({ shouldSetSingleNetwork: value }),

  isEstimationLoading: true,
  setIsEstimationLoading: value => set({ isEstimationLoading: value }),

  estimatedFee: [],
  setEstimatedFee: fees => set({ estimatedFee: fees }),

  executionIntervals: {
    [ExecutionType.Sequence]: 0,
    [ExecutionType.Fast]: 0,
  },
  setExecutionIntervals: intervals => set({ executionIntervals: intervals }),

  addReceiving: (address, amount, network) => {
    if (get().receiving.length > MAX_BULK_RECIPIENTS) return;

    set({
      receiving: [...get().receiving, { address, amount, network }],
      isDirty: address !== '' || amount !== '',
    });
  },
  removeReceiving: (index: number) => {
    if (get().receiving.length <= 2) {
      const { setReceivingData } = get();

      setReceivingData(index, {
        address: '',
        amount: '',
        network: undefined,
      });
      return;
    }
    set({
      receiving: get().receiving.filter((_, i) => i !== index),
    });
  },

  setReceivingData: (index: number, data) => {
    if (index < 0) return;

    const { addReceiving } = get();

    if (index >= get().receiving.length) {
      addReceiving(data.address, data.amount, data.network);
    }

    set({
      receiving: get().receiving.map((r, i) =>
        i === index ? { ...r, ...data } : r
      ),
      isDirty: data.address !== '' || data.amount !== '',
    });
  },
  setReceivingAddress: (index, address) =>
    set({
      receiving: get().receiving.map((r, i) =>
        i === index ? { ...r, address } : r
      ),
      isDirty: address !== '',
    }),
  setReceivingAmount: (index, amount) =>
    set({
      receiving: get().receiving.map((r, i) =>
        i === index ? { ...r, amount } : r
      ),
      isDirty: amount !== '',
    }),
  setReceivingNetwork: (index, network) => {
    const { shouldSetSingleNetwork } = get();
    set({
      receiving: get().receiving.map((r, i) =>
        i === index || (shouldSetSingleNetwork && !!network)
          ? { ...r, network }
          : r
      ),
      isDirty: network !== '',
    });
  },
  setExecutionType: type =>
    set({ executionType: type, isDirty: type !== ExecutionType.Sequence }),

  resetForm: () => {
    set({
      isDirty: false,
      networkFrom: undefined,
      currencyFrom: undefined,
      receiving: [],
      executionType: ExecutionType.Sequence,
    });
  },
}));

export const useAdvancedWalletStore = advancedWalletStore;
