import { useMemo } from 'react';
import { toHex } from 'viem';
import { useAccount as useEVMAccount, useChainId } from 'wagmi';
import { useTonAddress } from '@tonconnect/ui-react';
import { useStarknetAccount } from '@/starknet/hooks/account';
import { useWallet as useSolanaWallet } from '@solana/wallet-adapter-react';
import { NetworkTypes } from '@/providers/web3Provider';
import { INetwork } from '@/types/apiTypes';
import { useWallet as useTronWallet } from '@tronweb3/tronwallet-adapter-react-hooks';
import { useTronChainId } from '@/tron/hooks/useTronChainId';
import { chainIdNetworkMap } from '@tronweb3/tronwallet-adapters';

export const useWalletFrom = (network: INetwork | undefined) => {
  const evmChainId = useChainId();

  const tonAddress = useTonAddress();
  const { address: evmAddress } = useEVMAccount();
  const { publicKey: solanaAddress } = useSolanaWallet();
  const { address: starknetAddress, chainId: starknetChainId } =
    useStarknetAccount();
  const { address: tronAddress, wallet: tronWallet } = useTronWallet();
  const tronChainId = useTronChainId();

  const isFromStarknet = network?.network_type === NetworkTypes.STARKNET;
  const isFromTon = network?.network_type === NetworkTypes.TON;
  const isFromSolana = network?.network_type === NetworkTypes.SOLANA;
  const isFromTron = network?.network_type === NetworkTypes.TRON;
  const isFromEVM =
    !isFromStarknet && !isFromTon && !isFromSolana && !isFromTron;

  const isFromConnected =
    (isFromStarknet && !!starknetAddress) ||
    (isFromTon && !!tonAddress) ||
    (isFromSolana && !!solanaAddress) ||
    (isFromTron && !!tronAddress) ||
    (isFromEVM && !!evmAddress);

  const walletFrom = useMemo(() => {
    switch (network?.network_type) {
      case NetworkTypes.EVM:
        return evmAddress;
      case NetworkTypes.STARKNET:
        return starknetAddress;
      case NetworkTypes.TON:
        return tonAddress;
      case NetworkTypes.SOLANA:
        return solanaAddress?.toBase58();
      case NetworkTypes.TRON:
        return tronAddress || undefined;

      default:
        return evmAddress;
    }
  }, [
    evmAddress,
    starknetAddress,
    tonAddress,
    solanaAddress,
    tronAddress,
    network?.network_type,
  ]);

  const isWrongNetwork = useMemo(() => {
    if (!network) {
      return true;
    }

    if (network.network_type === NetworkTypes.TON) return false;
    if (network.network_type === NetworkTypes.SOLANA) return false;

    if (network.network_type === NetworkTypes.STARKNET)
      return !starknetChainId || toHex(starknetChainId) !== network.chainId;

    if (network.network_type === NetworkTypes.TRON && tronChainId) {
      return tronChainId !== Object.keys(chainIdNetworkMap)?.[0];
    }

    if (!evmChainId) return true;
    return evmChainId !== +network.chainId;
  }, [evmChainId, starknetChainId, tronChainId, network]);

  return { isFromConnected, walletFrom, isWrongNetwork };
};
