import { useAccount } from 'wagmi';
import { motion } from 'framer-motion';
import { Dropdown } from 'react-bootstrap';
import { BASE_URL } from '@/Config/ApiConfig';
import { Tooltip } from '@/components/tooltips';
import { ICurrency, INetwork } from '@/types/apiTypes';
import { Balance } from './Balance';

export const NetworksList = ({
  setNetwork,
  networks,
  category,
  currencies,
}: {
  networks: INetwork[];
  currencies: ICurrency[];
  setNetwork: (value: INetwork) => void;
  handleClose: () => void;
  category?: string;
}) => {
  const { isConnected } = useAccount();
  const onPress = (el: INetwork) => {
    setNetwork(el);
  };
  return (
    <div className=" networkListDiv">
      {networks.length !== 0 ? (
        <motion.div
          initial={{
            opacity: 0,
            filter: 'blur(10px)',
          }}
          variants={{
            animate: {
              opacity: 1,
              filter: 'blur(0px)',
            },
          }}
          className="networks-category-text mb-2"
        >
          {category ? category : 'Networks'}
          {category === 'Innovative Networks' && (
            <Tooltip>
              Innovative networks may have flexible max limits depending on
              market demand
            </Tooltip>
          )}
        </motion.div>
      ) : (
        <div className="text-white mb-2">No networks found</div>
      )}

      {networks.map(el => {
        return (
          <Dropdown.Item
            as="button"
            onClick={() => onPress(el)}
            className={`d-flex flex-row align-items-center cursor p-1 my-1 link-offset-3-hover ${
              !el.active ? 'disabled-network-wrapper' : ''
            }`}
            key={el.id}
            // disabled={!el.active}
          >
            <motion.div
              initial={{
                opacity: 0,
                filter: 'blur(10px)',
              }}
              variants={{
                animate: {
                  opacity: 1,
                  filter: 'blur(0px)',
                },
              }}
              className="d-flex w-100 flex-row align-items-center"
            >
              <div
                className={`d-flex flex-row  align-items-center cursor ${
                  !el.active ? 'disabled-network' : ''
                }`}
              >
                <div className="d-flex coinBtn border-0 p-1 me-3 btn-20">
                  <img
                    src={BASE_URL + el.network_image_url}
                    alt="token"
                    className="icon-16"
                  />
                </div>
                {el.name}
              </div>
              {isConnected && el.active && (
                <Balance
                  currency={currencies.find(
                    c => c.contract.network.id === el.id
                  )}
                />
              )}
              {!el.active && (
                <p className="disabled-network-description ms-auto">
                  Temporary not available
                </p>
              )}
            </motion.div>
          </Dropdown.Item>
        );
      })}
    </div>
  );
};
