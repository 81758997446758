import { useTonAddress } from '@tonconnect/ui-react';
import { useCallback, useEffect, useState } from 'react';
import { useAccount as useEVMAccount } from 'wagmi';

import { getSolanaBalance } from '@/solana/utils/getSolanaBalance';
import { useStarknetAccount } from '@/starknet/hooks/account';
import { getTronBalance } from '@/tron/utils/getTronBalance';
import { useWallet as useSolanaWallet } from '@solana/wallet-adapter-react';
import { useWallet as useTronWallet } from '@tronweb3/tronwallet-adapter-react-hooks';
import { useDebounceCallback } from 'usehooks-ts';
import { getEvmBalance } from '../evm/utils';
import { NetworkTypes } from '../providers/web3Provider';
import { getStarknetBalance } from '../starknet/utils/getStarknetBalance';
import { getTonBalance } from '../ton/utils';
import { ICurrency, INetwork } from '../types/apiTypes';
import { getRPCByNetwork } from '../utils/getRpcUrl';

export const useBalance = (token: ICurrency | undefined) => {
  const { address } = useEVMAccount();
  const tonAddress = useTonAddress();
  const { address: starknetAddress } = useStarknetAccount();
  const { publicKey: solanaAddress } = useSolanaWallet();
  const { address: tronAddress } = useTronWallet();

  const [balance, setBalance] = useState('0');
  const [loading, setLoading] = useState(true);

  const fetchBalanceCallback = useCallback(
    async (
      network?: INetwork,
      contractAddress?: string,
      contractDecimals?: number,
      showLoading = true,
      setState = true
    ) => {
      try {
        if (showLoading) {
          setLoading(true);
        }

        const rpcItem = await getRPCByNetwork(network);

        const isEVM =
          network?.network_type === NetworkTypes.EVM ||
          network?.network_type === NetworkTypes.ZK_SYNC_ERA;

        const isStarknet = network?.network_type === NetworkTypes.STARKNET;

        const isTon = network?.network_type === NetworkTypes.TON;

        const isSol = network?.network_type === NetworkTypes.SOLANA;

        const isTron = network?.network_type === NetworkTypes.TRON;

        if (address && isEVM) {
          const balance = await getEvmBalance(
            address,
            rpcItem.rpc,
            contractAddress,
            contractDecimals
          );
          if (setState) {
            setBalance(balance);
          }
          return balance;
        } else if (starknetAddress && isStarknet) {
          const balance = await getStarknetBalance(
            starknetAddress,
            rpcItem.rpc,
            true,
            contractAddress,
            contractDecimals
          );
          if (setState) {
            setBalance(balance);
          }
          return balance;
        } else if (tonAddress && isTon) {
          const balance = await getTonBalance(
            tonAddress,
            rpcItem.rpc,
            contractAddress,
            contractDecimals
          );
          if (setState) {
            setBalance(balance);
          }
          return balance;
        } else if (solanaAddress && isSol) {
          const balance = await getSolanaBalance(
            solanaAddress.toBase58(),
            rpcItem.rpc,
            contractAddress,
            contractDecimals
          );
          if (setState) {
            setBalance(balance);
          }
          return balance;
        } else if (isTron && tronAddress) {
          const balance = await getTronBalance(
            tronAddress,
            rpcItem.rpc,
            contractAddress,
            contractDecimals
          );
          if (setState) {
            setBalance(balance);
          }
          return balance;
        }

        return '0';
      } catch (error) {
        console.error(error);
        if (error instanceof Error) {
          throw new Error(error.message);
        }
        throw new Error('unhandled error');
      } finally {
        if (showLoading) {
          setLoading(false);
        }
      }
    },
    [address, starknetAddress, tonAddress, solanaAddress, tronAddress, token]
  );

  const fetchBalance = useDebounceCallback(fetchBalanceCallback, 500);

  useEffect(() => {
    if (token && !!fetchBalance) {
      fetchBalance(
        token?.contract?.network,
        token?.contract?.address,
        token?.decimals
      );
    } else {
      setBalance('0');
    }
  }, [token, fetchBalance]);

  return { balance, loading, fetchBalanceCallback };
};
