import { ethers } from 'ethers';
import { isAddress } from 'ethers/lib/utils';

export const isValidEvmAddress = (address?: string) => {
  if (
    !address ||
    !isAddress(address) ||
    address === ethers.constants.AddressZero
  ) {
    return false;
  }
  return true;
};
