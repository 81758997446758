import { ROUTES } from '@/constants/routes.constants';
import { PhaseItem } from '../JourneyItem';
import SunriseBg from '../assets/SunriseBg.png';
import SunriseNFT from '@/Assets/images/cardImage1.png';
import MiddayBg from '../assets/MiddayBg.png';
import MiddayNFT from '@/Assets/images/cardImage2.png';

interface PhasesBlockProps {
  showSunrise?: boolean;
}

export function PhasesBlock({ showSunrise = true }: PhasesBlockProps) {
  return (
    <div className="notifications-menu--group">
      <div className="notifications-menu--group-heading">Phases</div>
      <PhaseItem
        href={ROUTES.PHASE_MIDDAY_PAGE}
        target="_blank"
        thumbnailSrc={MiddayNFT}
        backgroundSrc={MiddayBg}
      >
        Take <strong>Midday Journey</strong> to get <strong>Midday NFT</strong>
        <div>
          <span>2 of 4</span> RetroBridge <span>Phases NFTs</span>
        </div>
      </PhaseItem>
      {showSunrise && (
        <PhaseItem
          href={ROUTES.PHASE_JOURNEYS_PAGE}
          target="_blank"
          thumbnailSrc={SunriseNFT}
          backgroundSrc={SunriseBg}
        >
          Take <strong>Sunrise Journey</strong> to get{' '}
          <strong>Sunrise NFT</strong>
          <div>
            <span>1 of 4</span> RetroBridge <span>Phases NFTs</span>
          </div>
        </PhaseItem>
      )}
    </div>
  );
}
