import external from '@/Assets/Icons/external.svg';
import globusIcon from '@/Assets/Icons/globusIcon.svg';
import { useAppStore } from '@/stores/app.store';
import { shortAddress } from '@/utils';
import { getBlockscanRoutes } from '@/utils/getBlockscanRoutes';
import { formatAmountBySymbol } from '@/utils/numbers';
import { motion } from 'framer-motion';
import { FC, useEffect, useState } from 'react';
import './NetworkLoaderItem.css';

type Props = {
  duration?: number;
  onFinish?: () => void;
  isPaused?: boolean;

  status: 'waiting' | 'progress' | 'filled' | 'delay' | 'success' | 'failed';
  networkSrc: string;
  network: string;
  wallet: string;
  amount: string;
  amountUsd: string;
  coinSymbol: string;
  imgSrc?: string;
  withoutLoader?: boolean;
  transaction?: string;
  blockScan: string;
};

const strokeWidth = 5;
const square = 100;
const radius = (square - strokeWidth) / 2;
const dashArray = radius * Math.PI * 2;

export const NetworkLoaderItem: FC<Props> = ({
  duration = 10,
  onFinish,
  isPaused,
  status,
  networkSrc: netwSrc,
  network: netw,
  wallet,
  imgSrc,
  amount,
  coinSymbol,
  withoutLoader,
  transaction,
  blockScan,
}) => {
  const isWaitingForConfirmation = useAppStore(s => s.isWaitingForConfirmation);
  const [isAnimationFinished, setIsAnimationFinished] = useState(false);

  useEffect(() => {
    if (isAnimationFinished && !isPaused && transaction) {
      onFinish && onFinish();
    }
  }, [isAnimationFinished, isPaused, transaction, onFinish]);

  const getGradientId = () => {
    switch (status) {
      case 'progress':
        return 'gradientProgress';
      case 'delay':
        return 'gradientDelay';
      case 'success':
        return 'gradientSuccess';
      case 'failed':
        return 'gradientFailed';
      default:
        return 'gradientProgress';
    }
  };

  const blockScanRoutes = getBlockscanRoutes(blockScan);

  return (
    <div className="network_loader_circle_wrapper">
      <div className="network_loader_circle_content">
        <svg
          width={square}
          height={square / 2 + strokeWidth}
          viewBox={`0 0 ${square} ${square / 2 + strokeWidth}`}
        >
          <defs>
            <linearGradient
              id="gradientProgress"
              gradientTransform="rotate(90)"
            >
              <stop offset="0%" stopColor="#E5C4FF" />
              <stop offset="100%" stopColor="#B19EFF" />
            </linearGradient>
            <linearGradient id="gradientDelay" gradientTransform="rotate(90)">
              <stop offset="0%" stopColor="#FF9878" />
              <stop offset="100%" stopColor="#FFC49A" />
            </linearGradient>
            <linearGradient id="gradientSuccess" gradientTransform="rotate(90)">
              <stop offset="0%" stopColor="#57B88F" />
              <stop offset="100%" stopColor="#B2FFBE" />
            </linearGradient>
            <linearGradient id="gradientFailed" gradientTransform="rotate(90)">
              <stop offset="0%" stopColor="#BE3243" />
              <stop offset="100%" stopColor="#FF959B" />
            </linearGradient>
            <mask id="maskPath">
              <path
                d={`
                  M ${strokeWidth / 2},${square / 2}
                  A ${radius},${radius} 0 0,1 ${square - strokeWidth / 2},${
                    square / 2
                  }
                  `}
                stroke="#fff"
                strokeWidth={strokeWidth}
                strokeLinecap="round"
                fill="none"
              />
            </mask>
          </defs>
          {!withoutLoader && (
            <g mask="url(#maskPath)">
              <path
                d={`
                    M ${strokeWidth / 2},${square / 2}
                    A ${radius},${radius} 0 0,1 ${square - strokeWidth / 2},${
                      square / 2
                    }
                `}
                strokeWidth={strokeWidth}
                className="network_loader_circle_background"
                style={{
                  strokeLinecap: 'round',
                }}
              />
              {status}
              {isPaused ? 'true' : 'false'}
              {status !== 'waiting' && (
                <motion.circle
                  cx={square / 2}
                  cy={square / 2}
                  r={radius}
                  strokeWidth={strokeWidth}
                  className="network_loader_circle_progress"
                  initial={{
                    strokeDashoffset:
                      status === 'success' || status === 'failed'
                        ? dashArray - (dashArray * 50) / 100
                        : dashArray - (dashArray * 0) / 100,
                  }}
                  animate={{
                    strokeDashoffset: !isPaused
                      ? dashArray - (dashArray * 50) / 100
                      : dashArray - (dashArray * 0) / 100,
                    stroke: `url(#${getGradientId()})`,
                  }}
                  transition={{ duration }}
                  onAnimationComplete={() => {
                    if (!isPaused) {
                      setIsAnimationFinished(true);
                    }
                  }}
                  style={{
                    strokeDasharray: dashArray,
                    strokeLinecap: 'round',
                    transition:
                      status === 'progress' || status === 'delay'
                        ? 'all 0.4s ease-out'
                        : 'none',
                    transformOrigin: `${square / 2}px ${square / 2}px`,
                    transform: 'rotate(-180deg)',
                  }}
                />
              )}
            </g>
          )}
        </svg>

        <div className="network_loader_circle_image">
          <img src={netwSrc} alt="" />
        </div>
      </div>

      <div className="network_loader_circle_address">
        <p>{shortAddress(wallet)}</p>

        {!!transaction && isAnimationFinished && !isWaitingForConfirmation ? (
          <a
            href={blockScanRoutes.tx + transaction}
            target="_blank"
            rel="noopener noreferrer"
            className="network_loader_circle_address_action"
          >
            <img src={external} className="" alt="" />
          </a>
        ) : (
          <a
            href={blockScanRoutes.address + wallet}
            target="_blank"
            rel="noopener noreferrer"
            className="network_loader_circle_address_action"
          >
            <img src={globusIcon} alt="" />
          </a>
        )}
      </div>

      <div className="network_loader_circle_info">
        <p className="network_loader_circle_title">{netw}</p>
        <div className="network_loader_circle_amount nowrap">
          <div className="network_loader_circle_token">
            <img src={imgSrc} alt="network icon" />
          </div>
          {formatAmountBySymbol(+amount, coinSymbol)} {coinSymbol}
        </div>
      </div>
    </div>
  );
};
