import clsx from 'clsx';
import React from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import InvalidAddressIcon from '@/Assets/Icons/invalid-address.svg';
import ValidAddressIcon from '@/Assets/Icons/valid-address.svg';
import '../../../SendPage.css';

export type WalletAddressInputProps = {
  value: string | undefined;
  setValue?: (value: string) => void;
  isVisible: boolean;
  isValidAddress: boolean;
};

function WalletAddressInput({
  value,
  setValue,
  isVisible,
  isValidAddress,
}: WalletAddressInputProps) {
  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (setValue) {
      setValue(e.target.value);
    }
  };

  return (
    <div className="pt-2">
      <AnimatePresence initial={false} mode="wait">
        {isVisible && (
          <motion.div
            initial={{ height: 0 }}
            animate={{ height: 'auto' }}
            exit={{ height: 0 }}
            transition={{ duration: 0.3 }}
            style={{ overflow: 'hidden' }}
            className={clsx('w-100 position-relative')}
          >
            <input
              className={'walletAddressInput w-100'}
              aria-label="Text input"
              placeholder="Enter your address here"
              onChange={onChangeInput}
              value={value}
            />
            {!!value && (
              <div className="address-icon">
                <img
                  src={isValidAddress ? ValidAddressIcon : InvalidAddressIcon}
                  width="20px"
                  height="20px"
                  alt=""
                />
              </div>
            )}
          </motion.div>
        )}
      </AnimatePresence>

      <AnimatePresence>
        {!isValidAddress && !!value && isVisible && (
          <motion.p
            initial={{ height: 0 }}
            animate={{ height: 'auto' }}
            exit={{ height: 0 }}
            transition={{ duration: 0.3 }}
            style={{ overflow: 'hidden' }}
            className="mb-0"
          >
            <span className="d-block fw-medium error-message mt-2">
              We couldn’t find this address, please check and try again.
            </span>
          </motion.p>
        )}
      </AnimatePresence>
    </div>
  );
}

export default WalletAddressInput;
