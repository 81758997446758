import clsx from 'clsx';
import {
  DialogContent,
  DialogOverlay,
  DialogPortal,
  Root,
} from '@radix-ui/react-dialog';
import { useAccount } from 'wagmi';
import Simplebar from 'simplebar-react';
import { useWindowSize } from 'usehooks-ts';
import { AnimatePresence, motion } from 'framer-motion';
import { useEffect, useMemo, useRef, useState } from 'react';
import searchIcon from '@/Assets/Icons/search.svg';
import { BASE_URL } from '@/Config/ApiConfig';
import { api } from '@/services/api';
import { useNetworksStore } from '@/stores/networks.store';
import { ICurrency, INetwork } from '@/types/apiTypes';
import { NetworkCategory } from '@/types/enums';
import { BalanceTooltip } from '../NetworkList/BalanceTooltip';
import { NetworksList } from '../NetworkList/networksList';

import 'simplebar-react/dist/simplebar.min.css';

export const categorySortPoints = (category: string) => {
  let categoryPoints = 0;
  if (category.toLowerCase().includes('popular')) {
    categoryPoints += 99;
  }
  if (category.toLowerCase().includes('evm')) {
    categoryPoints += 3;
  }
  if (category.toLowerCase().includes('l1')) {
    categoryPoints += 2;
  }
  if (category.toLowerCase().includes('btc')) {
    categoryPoints += -99;
  }

  return categoryPoints;
};

function ChooseNetworkModal({
  isOpen,
  onClose,
  setNetwork,
  title,
  currency,
}: {
  isOpen: boolean;
  onClose: () => void;
  setNetwork: (value: INetwork) => void;
  title: string;
  currency: ICurrency | undefined;
}) {
  const [selectedCategory, setSelectedCategory] = useState<NetworkCategory>(
    NetworkCategory.All
  );
  const { width } = useWindowSize();
  const targetRef = useRef<HTMLDivElement>(null);
  const [search, setSearch] = useState('');
  const networks = useNetworksStore(s => s.networks);

  const { isConnected } = useAccount();
  const [bridgePairs, setBridgePairs] = useState<ICurrency[]>([]);

  useEffect(() => {
    if (currency?.id)
      api.getBridgePairsForCurrency(currency.id, setBridgePairs);
  }, [currency?.id]);

  const filteredNetwork: INetwork[] = useMemo(() => {
    if (selectedCategory === NetworkCategory.All) {
      const filteredNetworks = [];

      for (let i = 0; i < networks.length; i++) {
        const network = Object.assign({}, networks[i]);
        if (network.bridge_popularity) {
          network.tag = null;
          network.category = 'Popular';
        }
        if (network.name.toLowerCase().includes(search.toLowerCase())) {
          filteredNetworks.push(network);
        }
      }

      return filteredNetworks;
    }

    return networks.filter(el => {
      return (
        el.name.toLowerCase().includes(search.toLowerCase()) &&
        el.category
          .toLowerCase()
          .includes(selectedCategory?.toLowerCase() ?? '')
      );
    });
  }, [networks, search, selectedCategory]);

  const networksByCategory = useMemo(() => {
    let networksByCategory: Record<string, INetwork[]> = {};

    networksByCategory = filteredNetwork.reduce<Record<string, INetwork[]>>(
      (prev, cur) => {
        const subCategory = cur.tag ?? cur.category;

        if (subCategory && subCategory in prev) {
          prev[subCategory].push(cur);
        } else if (subCategory) {
          prev[subCategory] = [cur];
        }

        return prev;
      },
      {}
    );

    if (networksByCategory['Popular']?.length > 0) {
      networksByCategory['Popular'] = networksByCategory['Popular']?.sort(
        (a, b) => {
          return (a.bridge_popularity ?? 0) - (b.bridge_popularity ?? 0);
        }
      );
    }

    return networksByCategory;
  }, [filteredNetwork]);

  const handleClose = () => {
    onClose();
    setSearch('');
    setSelectedCategory(NetworkCategory.All);
  };

  const onNetworkPress = (el: INetwork) => {
    setNetwork(el);
    handleClose();
  };

  return (
    <Root open={isOpen} modal onOpenChange={handleClose}>
      <AnimatePresence>
        {isOpen && (
          <DialogPortal forceMount>
            <DialogOverlay asChild>
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className="network-dialog--overlay"
              >
                <DialogContent asChild>
                  <motion.div
                    initial={
                      width <= 480 ? { opacity: 0, y: '100%' } : undefined
                    }
                    animate={width <= 480 ? { opacity: 1, y: '0%' } : undefined}
                    exit={width <= 480 ? { opacity: 1, y: '100%' } : undefined}
                    transition={{ duration: 0.2 }}
                    drag={width <= 480 ? 'y' : false}
                    dragSnapToOrigin
                    dragDirectionLock
                    dragConstraints={{ top: 0 }}
                    dragElastic={0.001}
                    ref={targetRef}
                    onDragEnd={(event, info) => {
                      if (
                        info.offset.y >
                        (targetRef.current?.clientHeight ?? 0) / 3
                      ) {
                        handleClose();
                      }
                    }}
                    className="network-dialog"
                  >
                    <h2 className="network-dialog--heading">{title}</h2>
                    <div className="d-flex flex-column gap-2">
                      <label className="network-dialog--search">
                        <img src={searchIcon} alt="search" />
                        <input
                          type="search"
                          placeholder={'Search by name'}
                          value={search}
                          onChange={e => setSearch(e.target.value)}
                        />
                      </label>
                      <div className="d-flex flex-wrap gap-2">
                        {Object.values(NetworkCategory).map(category => (
                          <button
                            key={category}
                            className={clsx(
                              'network-dialog--category',
                              selectedCategory === category && 'active'
                            )}
                            onClick={() => setSelectedCategory(category)}
                          >
                            <span className="z-1">{category}</span>
                          </button>
                        ))}
                      </div>
                    </div>

                    {filteredNetwork.length <= 0 && (
                      <div
                        key="no-networks"
                        className="text-center text-white mb-2"
                      >
                        No networks found
                      </div>
                    )}
                    <Simplebar
                      className="network-dialog--viewport"
                      key="networks"
                    >
                      {isConnected && (
                        <div className="network-balance-info">
                          Balance in{' '}
                          <span className="network-balance-info-currency">
                            <div className="network-balance-info-currency-icon">
                              <img
                                src={BASE_URL + currency?.image_url}
                                alt=""
                              />
                            </div>
                            {currency?.symbol === 'WETH'
                              ? 'ETH'
                              : currency?.symbol}
                            <BalanceTooltip>
                              <span className="network-balance-info-tooltip-trigger">
                                ?
                              </span>
                            </BalanceTooltip>
                          </span>
                        </div>
                      )}
                      <motion.div
                        variants={{
                          animate: {
                            transition: {
                              delayChildren: 0.1,
                              staggerChildren: 0.05,
                            },
                          },
                        }}
                        animate="animate"
                        className="select-source-modal-bottom "
                      >
                        {Object.keys(networksByCategory)
                          .sort((a, b) => {
                            const aPoints = categorySortPoints(a);
                            const bPoints = categorySortPoints(b);

                            return bPoints - aPoints;
                          })
                          .map(category => {
                            return (
                              <NetworksList
                                key={category}
                                setNetwork={onNetworkPress}
                                networks={networksByCategory[category]}
                                currencies={bridgePairs}
                                handleClose={handleClose}
                                category={
                                  category === 'undefined'
                                    ? undefined
                                    : category
                                }
                              />
                            );
                          })}
                      </motion.div>
                    </Simplebar>
                  </motion.div>
                </DialogContent>
              </motion.div>
            </DialogOverlay>
          </DialogPortal>
        )}
      </AnimatePresence>
    </Root>
  );
}

export default ChooseNetworkModal;
