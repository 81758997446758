import { ArrowDownIcon } from '@/Assets/Icons/arrow-down';
import { ArrowUpIcon } from '@/Assets/Icons/arrow-up';
import { BASE_URL } from '@/Config/ApiConfig';
import { LoaderSmall } from '@/components/LoaderSmall';
import { Tooltip } from '@/components/tooltips';
import {
  amountOptionsForLimits,
  maxPlatformDecimals,
} from '@/constants/numbers';
import { AVG_ORDER_EXECUTION_TIME_MS, MINUTE } from '@/constants/time';
import { getPrice, usePriceUpdater } from '@/stores/price_updater.store';
import { formatAmountBySymbol, toFixed } from '@/utils/numbers';
import { AnimatePresence, motion } from 'framer-motion';
import { useEffect, useMemo, useState } from 'react';
import { useShallow } from 'zustand/react/shallow';
import { useAdvancedWalletStore } from '@/stores/advanced.store';

import './styles.css';

export function AdvancedInfoBlock({
  totalToSent,
  error,
}: {
  totalToSent: number;
  error: string | null;
}) {
  const [isOpen, setIsOpen] = useState(false);

  const { prices } = usePriceUpdater();

  const [
    receiving,
    estimatedFee,
    currencyFrom,
    executionIntervals,
    executionType,
    isEstimationLoading,
  ] = useAdvancedWalletStore(
    useShallow(s => [
      s.receiving,
      s.estimatedFee,
      s.currencyFrom,
      s.executionIntervals,
      s.executionType,
      s.isEstimationLoading,
    ])
  );

  const price = useMemo(() => {
    const price = getPrice(currencyFrom?.symbol || '', prices);
    return price;
  }, [prices, currencyFrom?.symbol]);

  const [
    totalFee,

    totalPlatformFee,
    platformFeeInUSD,
    platformFeeCount,
    samePlatformFee,

    totalDestinationFee,
    destinationFeeInUSD,
    destinationFeeCount,
    sameDestinationFee,
  ] = useMemo(() => {
    let totalFee = 0;

    let platformFee = 0;
    let platformFeeCount = 0;
    const samePlatformFee = estimatedFee.every(
      fee => fee.platform_fee_in_usd === estimatedFee[0].platform_fee_in_usd
    );

    let destinationChainFee = 0;
    let destinationFeeCount = 0;
    const sameDestinationFee = estimatedFee.every(
      fee => fee.currency_out_id === estimatedFee[0].currency_out_id
    );

    for (const fee of estimatedFee) {
      totalFee += fee?.full_fee ?? 0;

      platformFee += fee?.platform_fee ?? 0;
      platformFeeCount++;

      destinationChainFee += fee?.blockchain_fee ?? 0;
      destinationFeeCount++;
    }

    return [
      totalFee,

      platformFee,
      estimatedFee?.[0]?.platform_fee_in_usd ?? 0,
      platformFeeCount,
      samePlatformFee,

      destinationChainFee,
      estimatedFee?.[0]?.blockchain_fee_in_usd ?? 0,
      destinationFeeCount,
      sameDestinationFee,
    ];
  }, [estimatedFee]);

  const shouldShowTotalToSent = useMemo(
    () => receiving.some(({ amount }) => !!amount),
    [receiving]
  );

  useEffect(() => {
    if (!shouldShowTotalToSent) setIsOpen(false);
  }, [shouldShowTotalToSent]);

  const estimatedTime = useMemo(() => {
    return Math.floor(
      (receiving.length *
        (executionIntervals[executionType] + AVG_ORDER_EXECUTION_TIME_MS)) /
        MINUTE
    );
  }, [executionIntervals, executionType, receiving.length]);

  const [minSend, maxSend] = useMemo(() => {
    if (!currencyFrom) return [0, 0];

    const minSend = formatAmountBySymbol(
      currencyFrom?.min_send,
      currencyFrom?.symbol,
      amountOptionsForLimits
    );

    const maxSend = formatAmountBySymbol(
      currencyFrom?.max_send,
      currencyFrom?.symbol,
      amountOptionsForLimits
    );

    return [minSend, maxSend];
  }, [currencyFrom]);

  return (
    <>
      <AnimatePresence>
        {shouldShowTotalToSent && (
          <motion.div
            initial={{
              opacity: 0,
              height: 0,
            }}
            animate={{
              opacity: 1,
              height: 'auto',
            }}
            exit={{
              opacity: 0,
              height: 0,
            }}
            className="overflow-hidden advanced-info-block"
          >
            <div className="d-flex align-items-center justify-content-between amount-receive-text-wrapper overflow-hidden">
              <div className="fs-6 text-white bold">
                Total to Sent{' '}
                {currencyFrom && (
                  <span className="min-max-text">
                    (min {minSend} - max {maxSend})
                  </span>
                )}
              </div>
              <div className="fs-6">
                <span className="fw-medium blanco">
                  ${toFixed(price.usd * (!error ? totalToSent : 0), 2)}
                </span>
              </div>
            </div>
            <div className="overflow-hidden text-secondary transitionHeight d-flex flex-direction-row justify-content-between topReceiveBlock infoBlock align-middle">
              <div className="fs-6 text-white bold advanced-info-amount">
                <div className="d-flex coinBtn border-0 p-1 advanced-info-amount-icon">
                  <img
                    src={BASE_URL + currencyFrom?.image_url}
                    alt="token"
                    className="ms1 networkIcon"
                  />
                </div>
                <p>
                  {toFixed(totalToSent, maxPlatformDecimals)}{' '}
                  {currencyFrom?.symbol}
                </p>
              </div>

              <div className="d-flex flex-row">
                <div className="d-flex flex-column receiveShortBlock">
                  {isEstimationLoading && (
                    <div className="d-flex justify-content-end align-items-center h-100">
                      <LoaderSmall width={18} height={18} />
                    </div>
                  )}
                  {!isEstimationLoading && (
                    <>
                      <p className="text-white bold">
                        about {estimatedTime} min
                      </p>
                      <span className="fw-medium blanco">
                        ${toFixed(price.usd * +totalFee, 2)} Total Fee
                      </span>
                    </>
                  )}
                </div>
                <div className="infoSeparator"></div>
                <button
                  className="toggleInfoBtn"
                  onClick={e => {
                    e.preventDefault();
                    setIsOpen(p => !p);
                  }}
                >
                  {isOpen ? <ArrowUpIcon /> : <ArrowDownIcon />}
                </button>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {!!error && (
          <motion.div
            initial={{
              opacity: 0,
              height: 0,
              paddingBottom: 0,
              marginTop: -8,
            }}
            animate={{
              opacity: 1,
              height: 'auto',
              marginTop: 0,
            }}
            exit={{
              opacity: 0,
              height: 0,
              paddingBottom: 0,
              marginTop: -8,
            }}
            transition={{ delay: shouldShowTotalToSent ? 0.1 : 0 }}
            className="blanco pb-2 error-message ps-3 overflow-hidden"
          >
            {error}
          </motion.div>
        )}
      </AnimatePresence>

      {isOpen && shouldShowTotalToSent && (
        <div className="overflow-hidden text-secondary transitionHeight d-flex flex-column advanced-receiveBlock infoBlock">
          <div className="d-flex flex-row justify-content-between font-size-12 align-items-center retrobridge-fee-wrapper">
            RetroBridge Fee:
            <div className="text-light fw-bolder">
              {samePlatformFee && (
                <span className="fw-medium blanco receiveUsdAmount">
                  {platformFeeCount} x ${toFixed(platformFeeInUSD, 2)}
                </span>
              )}
              {toFixed(totalPlatformFee, maxPlatformDecimals)}{' '}
              {currencyFrom?.symbol}
            </div>
          </div>
          <div className="d-flex flex-row justify-content-between font-size-12 align-items-center retrobridge-fee-wrapper">
            Destination Chain Fee:
            <Tooltip variant="dark">
              <span className="text-white">
                Cost of transfer to your addresses in Destination networks
              </span>
            </Tooltip>
            <div className="text-light fw-bolder">
              {sameDestinationFee && (
                <span className="fw-medium blanco receiveUsdAmount">
                  {destinationFeeCount} x $
                  {toFixed(destinationFeeInUSD, maxPlatformDecimals)}
                </span>
              )}
              {toFixed(totalDestinationFee, maxPlatformDecimals)}{' '}
              {currencyFrom?.symbol}
            </div>
          </div>
          <div className="d-flex flex-row justify-content-between align-items-center font-size-12 retrobridge-time-wrapper">
            Estimated Time:
            <div className="d-flex align-items-center text-light fw-bolder">
              {isEstimationLoading && <LoaderSmall width={14} height={14} />}
              {!isEstimationLoading && <>{estimatedTime} min</>}
              <Tooltip
                variant="dark"
                contentProps={{
                  align: 'end',
                }}
              >
                <span className="text-white">
                  Timeline for executing bridging orders in{' '}
                  {executionType.toLowerCase()}
                </span>
              </Tooltip>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
