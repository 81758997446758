import clsx from 'clsx';
import { useMemo } from 'react';
import { useBalance } from '@/hooks/useBalance';
import { ICurrency } from '@/types/apiTypes';
import { ROUND_TOKENS } from '@/constants/tokens';
import { getMinimalDecimalNumber } from '@/utils/numbers';
import { LoaderSmall } from '@/components/LoaderSmall';

interface BalanceProps {
  currency: ICurrency | undefined;
}

export function Balance({ currency }: BalanceProps) {
  const { balance, loading } = useBalance(currency);

  const formattedBalance = useMemo(() => {
    const shouldRoundToken = ROUND_TOKENS.includes(
      currency?.symbol.toLowerCase() ?? ''
    );

    const formatted = +balance <= 0 ? 0 : getMinimalDecimalNumber(balance);

    if (+formatted <= 0) return '0.00';

    if (shouldRoundToken) {
      return +formatted < 0.0001 ? '0.00' : formatted;
    }

    return +formatted < 0.000001 ? '0.00' : formatted;
  }, [balance, currency?.symbol]);

  if (!currency) return null;
  return (
    <p
      className={clsx(
        'network-balance',
        +formattedBalance <= 0 && 'network-balance-disabled',
        loading && 'network-balance-loading'
      )}
    >
      {loading ? (
        <LoaderSmall
          width={16}
          height={16}
          className="network-balance-loader"
        />
      ) : (
        formattedBalance
      )}{' '}
      {currency?.symbol}
    </p>
  );
}
