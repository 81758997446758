import { useEffect, useMemo, useRef, useState } from 'react';
import { CurrencySelect } from '@/pages/SendPage/components/CurrencySelect';
import { useAdvancedWalletStore } from '@/stores/advanced.store';
import './styles.css';

export function TokenFromInput() {
  const [networkFrom, currencyFrom, setCurrencyFrom, currencies] =
    useAdvancedWalletStore(s => [
      s.networkFrom,
      s.currencyFrom,
      s.setCurrencyFrom,
      s.currencies,
    ]);
  const [inputWidth, setInputWidth] = useState(0);
  const inputRef = useRef<HTMLInputElement>(null);

  const sourceCurrencies = useMemo(
    () => currencies.filter(c => c.contract.network.id === networkFrom?.id),
    [currencies, networkFrom]
  );

  useEffect(() => {
    let animationFrameId: number;

    const handleInput = () => {
      if (inputRef.current) {
        const inputText = inputRef.current.value;
        const textWidth = getTextWidth(inputText, inputRef.current);

        if (textWidth !== inputWidth) {
          animationFrameId = requestAnimationFrame(() => {
            setInputWidth(textWidth);
          });
        }
      }
    };

    const inputElement = inputRef.current;
    inputElement?.addEventListener('input', handleInput);

    return () => {
      inputElement?.removeEventListener('input', handleInput);
      cancelAnimationFrame(animationFrameId);
    };
  }, [inputWidth]);

  const getTextWidth = (text: string, inputElement: HTMLInputElement) => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    if (context) {
      const style = window.getComputedStyle(inputElement);
      context.font = `${style.fontSize} ${style.fontFamily}`;
      return context.measureText(text).width;
    }
    return 0;
  };

  return (
    <div className="advanced-token-from-input-wrapper">
      <p>Token</p>
      <div className="advanced-network-select w-100">
        <div className="advanced-token-from-input">
          <CurrencySelect
            value={currencyFrom}
            onChange={setCurrencyFrom}
            options={sourceCurrencies}
          />
        </div>
      </div>
    </div>
  );
}
