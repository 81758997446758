import { isOneDomain as IS_ONE_DOMAIN } from '@/Config';
import { STAGES, stage } from '../Config/ApiConfig';

export const DAPP_EXPLORER_PAGE = '/dapp-explorer';
export const EXPLORER_PAGE = '/explorer';
export const PROGRESS_PAGE = '/progress';
export const ADVANCED_PROGRESS_PAGE = '/progress/advanced';
export const HOME_PAGE = '/';
export const SEND_PAGE = '/send';
export const PHASES_PAGE = '/phases';
export const PHASE_SUNRISE_PAGE = '/phases/sunrise-journey';
export const PHASE_MIDDAY_PAGE = '/phases/midday-journey';

export const PHASE_LINEA_ADVENTURE_PAGE = '/adventures/linea';
export const PHASE_ZKSYNC_ADVENTURE_PAGE = '/adventures/zksync';
export const PHASE_MULTICHAIN_ADVENTURE_PAGE = '/adventures/multichain';
export const PHASE_BASE_ADVENTURE_PAGE = '/adventures/base';
export const PHASE_SCROLL_ADVENTURE_PAGE = '/adventures/scroll';
export const PHASE_BLAST_ADVENTURE_PAGE = '/adventures/blast';
export const PHASE_TAIKO_ADVENTURE_PAGE = '/adventures/taiko';
export const PHASE_TON_ADVENTURE_PAGE = '/adventures/ton';

export const PHASE_JOURNEYS_PAGE = '/phases/journeys';
export const PHASE_ADVENTURES_PAGE = '/adventures';
export const DEV_API_PAGE = '/for-developers';
export const PRIVACY_POLICY_PAGE = '/terms-conditions';
export const AUDIT_PAGE = window.location.origin + '/audit.pdf';
export const MONITORING_AUDIT_PAGE =
  window.location.origin + '/security-monitoring.pdf';
export const QUANTSTAMP_AUDIT_PAGE =
  window.location.origin + '/quantstamp-sc-audit.pdf';
export const FAQ_PAGE = '/faq';
export const DOCS_LINK = 'https://docs.retrobridge.io/';

export enum Routes {
  DAPP_EXPLORER_PAGE = 'DAPP_EXPLORER_PAGE',
  HOME_PAGE = 'HOME_PAGE',
  EXPLORER_PAGE = 'EXPLORER_PAGE',
  PROGRESS_PAGE = 'PROGRESS_PAGE',
  ADVANCED_PROGRESS_PAGE = 'ADVANCED_PROGRESS_PAGE',
  SEND_PAGE = 'SEND_PAGE',
  PRIVACY_POLICY_PAGE = 'PRIVACY_POLICY_PAGE',
  FAQ_PAGE = 'FAQ_PAGE',
  LANDING_PAGE = 'LANDING_PAGE',
  DEV_API_PAGE = 'DEV_API_PAGE',

  PHASES_PAGE = 'PHASES_PAGE',
  PHASE_PAGE = 'PHASE_PAGE',
  PHASE_JOURNEYS_PAGE = 'PHASE_JOURNEYS_PAGE',
  PHASE_ADVENTURES_PAGE = 'PHASE_ADVENTURES_PAGE',

  PHASE_MIDDAY_PAGE = 'PHASE_MIDDAY_PAGE',

  PHASE_LINEA_ADVENTURE_PAGE = 'PHASE_LINEA_ADVENTURE_PAGE',
  PHASE_ZKSYNC_ADVENTURE_PAGE = 'PHASE_ZKSYNC_ADVENTURE_PAGE',
  PHASE_MULTICHAIN_ADVENTURE_PAGE = 'PHASE_MULTICHAIN_ADVENTURE_PAGE',
  PHASE_BASE_ADVENTURE_PAGE = 'PHASE_BASE_ADVENTURE_PAGE',
  PHASE_SCROLL_ADVENTURE_PAGE = 'PHASE_SCROLL_ADVENTURE_PAGE',
  PHASE_BLAST_ADVENTURE_PAGE = 'PHASE_BLAST_ADVENTURE_PAGE',
  PHASE_TAIKO_ADVENTURE_PAGE = 'PHASE_TAIKO_ADVENTURE_PAGE',
  PHASE_TON_ADVENTURE_PAGE = 'PHASE_TON_ADVENTURE_PAGE',

  AUDIT_PAGE = 'AUDIT_PAGE',
  MONITORING_AUDIT_PAGE = 'MONITORING_AUDIT_PAGE',
  QUANTSTAMP_AUDIT_PAGE = 'QUANTSTAMP_AUDIT_PAGE',

  TESTNET_SEND_PAGE = 'TESTNET_SEND_PAGE',
}

const localRoutes: Record<Routes, string> = {
  [Routes.DAPP_EXPLORER_PAGE]: '/dapp-explorer',
  [Routes.HOME_PAGE]: '/',
  [Routes.EXPLORER_PAGE]: '/explorer',
  [Routes.PROGRESS_PAGE]: '/progress',
  [Routes.ADVANCED_PROGRESS_PAGE]: '/progress/advanced',
  [Routes.PRIVACY_POLICY_PAGE]: '/terms-conditions',
  [Routes.FAQ_PAGE]: '/faq',
  [Routes.SEND_PAGE]: '/send',
  [Routes.LANDING_PAGE]: '/',
  [Routes.DEV_API_PAGE]: '/for-developers',
  [Routes.PHASES_PAGE]: '/phases',
  [Routes.PHASE_PAGE]: '/phases/sunrise-journey',
  [Routes.PHASE_JOURNEYS_PAGE]: '/phases/journeys',
  [Routes.PHASE_ADVENTURES_PAGE]: '/adventures',

  [Routes.PHASE_MIDDAY_PAGE]: '/phases/midday-journey',

  [Routes.PHASE_LINEA_ADVENTURE_PAGE]: '/adventures/linea',
  [Routes.PHASE_ZKSYNC_ADVENTURE_PAGE]: '/adventures/zksync',
  [Routes.PHASE_MULTICHAIN_ADVENTURE_PAGE]: '/adventures/multichain',
  [Routes.PHASE_BASE_ADVENTURE_PAGE]: '/adventures/base',
  [Routes.PHASE_SCROLL_ADVENTURE_PAGE]: '/adventures/scroll',
  [Routes.PHASE_BLAST_ADVENTURE_PAGE]: '/adventures/blast',
  [Routes.PHASE_TAIKO_ADVENTURE_PAGE]: '/adventures/taiko',
  [Routes.PHASE_TON_ADVENTURE_PAGE]: '/adventures/ton',

  [Routes.AUDIT_PAGE]: AUDIT_PAGE,
  [Routes.MONITORING_AUDIT_PAGE]: MONITORING_AUDIT_PAGE,
  [Routes.QUANTSTAMP_AUDIT_PAGE]: QUANTSTAMP_AUDIT_PAGE,

  [Routes.TESTNET_SEND_PAGE]: 'https://testnet.retrobridge.dev',
};

const stagingRoutes: Record<Routes, string> = {
  [Routes.DAPP_EXPLORER_PAGE]: 'https://retrobridge.dev/dapp-explorer',
  [Routes.HOME_PAGE]: '/',
  [Routes.EXPLORER_PAGE]: 'https://explorer.retrobridge.dev',
  [Routes.PROGRESS_PAGE]: '/progress',
  [Routes.ADVANCED_PROGRESS_PAGE]: '/progress/advanced',
  [Routes.PRIVACY_POLICY_PAGE]:
    window.location.host === 'retrobridge.dev'
      ? '/terms-conditions'
      : 'https://retrobridge.dev/terms-conditions',
  [Routes.FAQ_PAGE]:
    window.location.host === 'retrobridge.dev'
      ? '/faq'
      : 'https://retrobridge.dev/faq',
  [Routes.SEND_PAGE]: 'https://app.retrobridge.dev',
  [Routes.LANDING_PAGE]: 'https://retrobridge.dev',
  [Routes.DEV_API_PAGE]: 'https://retrobridge.dev/for-developers',
  [Routes.PHASES_PAGE]: 'https://retrobridge.dev/phases',
  [Routes.PHASE_PAGE]: 'https://retrobridge.dev/phases/sunrise-journey',
  [Routes.PHASE_JOURNEYS_PAGE]: 'https://retrobridge.dev/phases/journeys',

  [Routes.PHASE_MIDDAY_PAGE]: 'https://retrobridge.dev/phases/midday-journey',

  [Routes.PHASE_ADVENTURES_PAGE]: 'https://retrobridge.dev/adventures',
  [Routes.PHASE_LINEA_ADVENTURE_PAGE]:
    'https://retrobridge.dev/adventures/linea',
  [Routes.PHASE_ZKSYNC_ADVENTURE_PAGE]:
    'https://retrobridge.dev/adventures/zksync',
  [Routes.PHASE_MULTICHAIN_ADVENTURE_PAGE]:
    'https://retrobridge.dev/adventures/multichain',
  [Routes.PHASE_BASE_ADVENTURE_PAGE]: 'https://retrobridge.dev/adventures/base',
  [Routes.PHASE_SCROLL_ADVENTURE_PAGE]:
    'https://retrobridge.dev/adventures/scroll',
  [Routes.PHASE_BLAST_ADVENTURE_PAGE]:
    'https://retrobridge.dev/adventures/blast',
  [Routes.PHASE_TAIKO_ADVENTURE_PAGE]:
    'https://retrobridge.dev/adventures/taiko',
  [Routes.PHASE_TON_ADVENTURE_PAGE]: 'https://retrobridge.dev/adventures/ton',

  [Routes.AUDIT_PAGE]: 'https://retrobridge.dev/audit.pdf',
  [Routes.MONITORING_AUDIT_PAGE]:
    'https://retrobridge.dev/security-monitoring.pdf',
  [Routes.QUANTSTAMP_AUDIT_PAGE]:
    'https://retrobridge.dev/quantstamp-sc-audit.pdf',

  [Routes.TESTNET_SEND_PAGE]: 'https://testnet.retrobridge.dev',
};

const productionRoutes: Record<Routes, string> = {
  [Routes.DAPP_EXPLORER_PAGE]: 'https://retrobridge.io/dapp-explorer',
  [Routes.HOME_PAGE]: '/',
  [Routes.EXPLORER_PAGE]: 'https://explorer.retrobridge.io',
  [Routes.PROGRESS_PAGE]: '/progress',
  [Routes.ADVANCED_PROGRESS_PAGE]: '/progress/advanced',
  [Routes.PRIVACY_POLICY_PAGE]:
    window.location.host === 'retrobridge.io'
      ? '/terms-conditions'
      : 'https://retrobridge.io/terms-conditions',
  [Routes.FAQ_PAGE]:
    window.location.host === 'retrobridge.io'
      ? '/faq'
      : 'https://retrobridge.io/faq',
  [Routes.SEND_PAGE]: 'https://app.retrobridge.io',
  [Routes.LANDING_PAGE]: 'https://retrobridge.io',
  [Routes.DEV_API_PAGE]: 'https://retrobridge.io/for-developers',
  [Routes.PHASES_PAGE]: 'https://retrobridge.io/phases',
  [Routes.PHASE_PAGE]: 'https://retrobridge.io/phases/sunrise-journey',
  [Routes.PHASE_JOURNEYS_PAGE]: 'https://retrobridge.io/phases/journeys',
  [Routes.PHASE_ADVENTURES_PAGE]: 'https://retrobridge.io/adventures',

  [Routes.PHASE_MIDDAY_PAGE]: 'https://retrobridge.io/phases/midday-journey',

  [Routes.PHASE_LINEA_ADVENTURE_PAGE]:
    'https://retrobridge.io/adventures/linea',
  [Routes.PHASE_ZKSYNC_ADVENTURE_PAGE]:
    'https://retrobridge.io/adventures/zksync',
  [Routes.PHASE_MULTICHAIN_ADVENTURE_PAGE]:
    'https://retrobridge.io/adventures/multichain',
  [Routes.PHASE_BASE_ADVENTURE_PAGE]: 'https://retrobridge.io/adventures/base',
  [Routes.PHASE_SCROLL_ADVENTURE_PAGE]:
    'https://retrobridge.io/adventures/scroll',
  [Routes.PHASE_BLAST_ADVENTURE_PAGE]:
    'https://retrobridge.io/adventures/blast',
  [Routes.PHASE_TAIKO_ADVENTURE_PAGE]:
    'https://retrobridge.io/adventures/taiko',
  [Routes.PHASE_TON_ADVENTURE_PAGE]: 'https://retrobridge.io/adventures/ton',

  [Routes.AUDIT_PAGE]: 'https://retrobridge.io/audit.pdf',
  [Routes.MONITORING_AUDIT_PAGE]:
    'https://retrobridge.io/security-monitoring.pdf',
  [Routes.QUANTSTAMP_AUDIT_PAGE]:
    'https://retrobridge.io/quantstamp-sc-audit.pdf',

  [Routes.TESTNET_SEND_PAGE]: 'https://testnet.retrobridge.io',
};

const routes: Record<STAGES, Record<Routes, string>> = {
  development: localRoutes,
  production: productionRoutes,
  staging: stagingRoutes,
};
export const ROUTES = IS_ONE_DOMAIN ? routes['development'] : routes[stage];
