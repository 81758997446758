import { starknetWallets } from '../constants/wallets.constant';
import { useStarknetAccount } from './account';
import starknet from '@/Assets/images/wallets/starknet.png';

export const useStarknetConnectedIcon = () => {
  const { connector: starknetConnector } = useStarknetAccount();

  const starknetWallet = starknetWallets.find(
    item => item.id === starknetConnector?.id
  );

  return starknetWallet?.icon || starknet;
};
