import peckShield from '@/Assets/Icons/PeckShield.png';
import Quantstamp from '@/Assets/Icons/Quantstamp.png';
import { Shield } from '@/Assets/Icons/Shield';
import { ArrowDownIcon } from '@/Assets/Icons/arrow-down';
import { ROUTES } from '@/constants/routes.constants';
import {
  PopoverContent,
  PopoverPortal,
  PopoverTrigger,
  Root,
} from '@radix-ui/react-popover';
import { Link } from 'react-router-dom';

export function AuditsPopover() {
  return (
    <Root>
      <PopoverTrigger className="footer-nav-item footer-audits-menu--trigger">
        Audits
        <ArrowDownIcon />
      </PopoverTrigger>
      <PopoverPortal>
        <PopoverContent
          align="end"
          sideOffset={15}
          hideWhenDetached
          className="footer-audits-menu"
        >
          <Link
            to={ROUTES.QUANTSTAMP_AUDIT_PAGE}
            target="_blank"
            className="footer-audits-menu-item"
          >
            <img src={Quantstamp} draggable={false} alt="" />
            <span>Smart Contracts Audit</span>
          </Link>
          <Link
            to={ROUTES.AUDIT_PAGE}
            target="_blank"
            className="footer-audits-menu-item"
          >
            <span className="d-flex align-items-center text-white fw-bold bold">
              <Shield width={17} height={17} />
              EBezuhlyi
            </span>
            <span>Security Audit</span>
          </Link>
          <Link
            to={ROUTES.MONITORING_AUDIT_PAGE}
            target="_blank"
            className="footer-audits-menu-item"
          >
            <img src={peckShield} draggable={false} alt="" />
            <span>Security Monitoring</span>
          </Link>
        </PopoverContent>
      </PopoverPortal>
    </Root>
  );
}
