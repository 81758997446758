import { motion } from 'framer-motion';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useBeforeUnload } from 'react-router-dom';
import { GitBook } from '@/Assets/Icons/GitBoook';
import { bulkApi } from '@/services/BulkApi';
import { api } from '@/services/api';
import { useNetworksStore } from '@/stores/networks.store';
import { useShallow } from 'zustand/react/shallow';
import { useWalletFrom } from '@/hooks/useWalletFrom';
import { SubmitButton } from '../SubmitButton';
import { useAdvancedWalletStore } from '@/stores/advanced.store';
import { NetworkFromInput } from './NetworkFromInput';
import { TokenFromInput } from './TokenFromInput';
import { SelectReceivingAddress } from './SelectReceivingAddress';
import { SelectExecutionType } from './SelectExecutionType';
import { AdvancedInfoBlock } from './AdvancedInfoBlock';
import { isValidAddress } from '@/utils/isValidAddress';
import { useBalance } from '@/hooks/useBalance';
import './styles.css';

export type SendAdvancedProps = {
  openConfirm: () => void;
};

export function SendAdvanced({ openConfirm }: SendAdvancedProps) {
  const [error, setError] = useState<string | null>('');

  const networks = useNetworksStore(s => s.networks);

  const [
    receiving,
    estimatedFee,
    networkFrom,
    addReceiving,
    isDirty,
    currencies,
    isEstimationLoading,
    setIsEstimationLoading,
    currencyFrom,
  ] = useAdvancedWalletStore(state => [
    state.receiving,
    state.estimatedFee,
    state.networkFrom,
    state.addReceiving,
    state.isDirty,
    state.currencies,
    state.isEstimationLoading,
    state.setIsEstimationLoading,
    state.currencyFrom,
  ]);

  const { balance } = useBalance(currencyFrom);
  const { walletFrom } = useWalletFrom(networkFrom);

  useEffect(() => {
    if (networks.length <= 0 || !networkFrom?.id) return;

    if (receiving.length === 0) {
      addReceiving('', '', undefined);
      addReceiving('', '', undefined);
    }
  }, [receiving.length, networks, networkFrom?.id]);

  const receivingNetworks = useAdvancedWalletStore(
    useShallow(s => [...new Set(s.receiving.map(r => r.network))])
  );

  useEffect(() => {
    if (!networkFrom?.id || receivingNetworks.length <= 0) return;

    bulkApi.getCurrencies([
      networkFrom.id,
      ...(receivingNetworks.filter(el => el !== undefined) as string[]),
    ]);
  }, [networkFrom?.id, receivingNetworks]);

  useEffect(() => {
    if (currencyFrom?.id) api.getBridgePairsForCurrency(currencyFrom.id);
  }, [currencyFrom?.id]);

  useEffect(() => {
    bulkApi.getExecutionTimes();
  }, []);

  useEffect(() => {
    if (
      !currencies.length ||
      networks.length <= 0 ||
      receivingNetworks.length <= 0
    )
      return;

    setIsEstimationLoading(true);

    const timer = setTimeout(() => {
      bulkApi.getEstimatedFee(walletFrom, receiving).finally(() => {
        setIsEstimationLoading(false);
      });
    }, 2000);

    return () => {
      clearTimeout(timer);
    };
  }, [
    walletFrom,
    receiving,
    currencies,
    networks,
    receivingNetworks,
    currencyFrom?.id,
    setIsEstimationLoading,
  ]);

  useBeforeUnload(e => {
    if (
      isDirty ||
      receiving.some(r => !!r.address || !!r.amount || !r.network)
    ) {
      e.preventDefault();
      return true;
    }
    return undefined;
  });

  const totalFee = useMemo(
    () => estimatedFee.reduce((pv, cv) => (pv += cv?.full_fee ?? 0), 0),
    [estimatedFee]
  );

  const totalToSent = useMemo(() => {
    return receiving.reduce((acc, { amount }) => acc + +amount, 0) + totalFee;
  }, [receiving, totalFee]);

  const validateForm = useCallback(() => {
    if (!networkFrom || !currencyFrom) return false;

    for (let i = 0; i < receiving.length; i++) {
      const { address, amount, network } = receiving[i];
      if (!address || !amount || !network) return false;

      const networkTo = networks.find(n => n.id === network);
      if (!isValidAddress(address, networkTo?.network_type)) return false;
      if (!networkTo) return false;

      if (parseFloat(amount) > currencyFrom.max_send) {
        setError('The amount to send exceeds the maximum limit');
        return false;
      }

      if (parseFloat(amount) < currencyFrom.min_send) {
        setError('The amount to send is less than the minimal limit');
        return false;
      }
    }

    if (+totalToSent > currencyFrom?.max_send) {
      setError('The amount to send exceeds the maximum limit');
      return false;
    }

    if (+totalToSent > +balance) {
      setError('The amount to send exceeds balance');
      return false;
    }

    setError(null);
    return true;
  }, [receiving, networkFrom, currencyFrom, networks, balance, totalToSent]);

  const isFormValid = useMemo(() => validateForm(), [validateForm]);
  const shouldDisable = !isFormValid || isEstimationLoading;

  return (
    <>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <div className="advanced-form formBody">
          <div className="d-flex align-items-center advanced-mode-header">
            <a
              href="https://docs.retrobridge.io/retrobridge-app/advanced-mode"
              target="_blank"
              rel="noopener noreferrer"
              className="advanced-mode-help"
            >
              <GitBook color="#CCC3FF" />
              Advanced mode
            </a>
          </div>

          <div className="advanced-from-input">
            <div className="advanced-from-input-fields">
              <NetworkFromInput />
              <TokenFromInput />
            </div>
          </div>
          <SelectReceivingAddress />
          <SelectExecutionType />
          <AdvancedInfoBlock error={error} totalToSent={totalToSent} />
          <SubmitButton
            title="Create Advanced Transfer"
            networkFrom={networkFrom}
            currencyFrom={currencyFrom}
            isDisabled={shouldDisable}
            onSubmit={openConfirm}
          />
        </div>
      </motion.div>
    </>
  );
}
