import scrollMasterBridgerBadge from '../assets/ScrollMasterBridger.png';
import scrollMasterSwapperBadge from '../assets/ScrollMasterSwapper.png';
import scrollLogo from '@/Assets/images/journeys/scroll/scrollIcon.png';
import { OtherItem } from './OtherItem';
import './styles.css';

export function Other() {
  return (
    <div className="d-flex flex-column gap-3">
      <OtherItem
        backgroundSrc={require('../assets/ScrollBadgeBg.png')}
        href="https://scroll.io/canvas/badge-contract/0x4F4d33693A0C454a87Dd39cf21171F0d585Af0bD"
        target="_blank"
      >
        <span className="notifications-menu--other-item--content master-bridger">
          <strong>
            Complete 2 bridges from any <br />
            EVM network to <img src={scrollLogo} alt="" /> Scroll <br />
            and claim <span className="text-gradient">MasterBridger</span> Badge
          </strong>
        </span>
        <div className="notifications-menu--other-item--image">
          <img src={scrollMasterBridgerBadge} draggable={false} alt="" />
        </div>
      </OtherItem>
      <OtherItem
        backgroundSrc={require('../assets/ScrollBadgeBg.png')}
        href="https://scroll.io/canvas/badge-contract/0x5cEDbA9376e447dd23f196bF66985c31fDFD8559"
        target="_blank"
      >
        <span className="notifications-menu--other-item--content master-swapper">
          <strong>
            Complete 2 swaps from any <br />
            EVM network to <img src={scrollLogo} alt="" /> Scroll <br />
            and claim <span className="text-gradient">MasterSwapper</span> Badge
          </strong>
        </span>
        <div className="notifications-menu--other-item--image">
          <img src={scrollMasterSwapperBadge} draggable={false} alt="" />
        </div>
      </OtherItem>
    </div>
  );
}
