import { Trashcan } from '@/Assets/Icons/Trashcan';
import { ArrowDownIcon } from '@/Assets/Icons/arrow-down';
import InvalidAddressIcon from '@/Assets/Icons/invalid-address.svg';
import ValidAddressIcon from '@/Assets/Icons/valid-address.svg';
import { BASE_URL } from '@/Config/ApiConfig';
import { useNetworksStore } from '@/stores/networks.store';
import { useWalletStore } from '@/stores/wallet.store';
import { PopoverTrigger } from '@radix-ui/react-popover';
import clsx from 'clsx';
import { useMemo } from 'react';
import { useWindowSize } from 'usehooks-ts';
import {
  AdvancedWalletStore,
  useAdvancedWalletStore,
} from '@/stores/advanced.store';
import { isValidAddress } from '@/utils/isValidAddress';
import { AdvancedNetworkSelect } from '../AdvancedNetworkSelect';

interface ReceivingItemProps {
  item: AdvancedWalletStore['receiving'][0];
  i: number;
  disabled?: boolean;
}

export function ReceivingItem({ item, i, disabled }: ReceivingItemProps) {
  const networks = useNetworksStore(s => s.networks);
  const { allCurrencyPairs } = useWalletStore();
  const [networkFrom] = useAdvancedWalletStore(s => [s.networkFrom]);

  const [
    currencyFrom,
    setReceivingAddress,
    setReceivingAmount,
    setReceivingNetwork,
    removeReceiving,
  ] = useAdvancedWalletStore(s => [
    s.currencyFrom,
    s.setReceivingAddress,
    s.setReceivingAmount,
    s.setReceivingNetwork,
    s.removeReceiving,
  ]);

  const availableNetworks = useMemo(() => {
    if (networkFrom && allCurrencyPairs) {
      return allCurrencyPairs
        .filter(p => p.contract.network.id !== networkFrom.id)
        .map(c => c.contract.network);
    } else {
      return [];
    }
  }, [networkFrom, allCurrencyPairs]);

  const isAddressValid = useMemo(() => {
    const network = networks.find(n => n.id === item.network);
    return isValidAddress(item.address, network?.network_type);
  }, [networks, item.address, item.network]);

  const handleAmountChange = (amount: string) => {
    if (disabled) return;

    const splitedInput = amount.split('.');
    if (splitedInput[0]?.length > 5) return;
    if (splitedInput[1]?.length > 6) return;
    if (!/^[0-9]*(\.([0-9]+)?)?$/.test(amount)) return;

    setReceivingAmount(i, amount);
  };

  const isAmountValid = useMemo(() => {
    if (!currencyFrom) return true;

    if (item.amount === '') return true;

    return (
      +item.amount <= currencyFrom?.max_send &&
      +item.amount >= currencyFrom?.min_send
    );
  }, [item.amount, currencyFrom]);

  const { width } = useWindowSize();

  return (
    <>
      {width < 746 && (
        <div className="select-receiving-mobile-header">
          <p>Receiving Address #{i + 1}</p>
          {!disabled && (
            <button
              className="select-receiving--delete"
              onClick={() => removeReceiving(i)}
            >
              <Trashcan />
            </button>
          )}
        </div>
      )}

      <label
        className={clsx(
          'select-receiving-input first-input',
          disabled && 'select-receiving-input--disabled',
          !item.address && 'select-receiving-input--empty'
        )}
      >
        {!!item.address && (
          <div className="d-flex justify-content-center align-items-center">
            <img
              src={isAddressValid ? ValidAddressIcon : InvalidAddressIcon}
              width="14px"
              height="14px"
              alt=""
            />
          </div>
        )}
        <input
          type="text"
          value={item.address}
          readOnly={disabled}
          placeholder="Enter wallet address"
          onChange={e => {
            if (disabled) return;
            setReceivingAddress(i, e.target.value);
          }}
        />
      </label>
      <label
        className={clsx(
          'select-receiving-input second-input',
          disabled && 'select-receiving-input--disabled'
        )}
      >
        <input
          type="text"
          value={item.amount}
          className={clsx(!isAmountValid && 'error-message')}
          placeholder="0.00"
          onChange={e => {
            handleAmountChange(e.target.value);
          }}
          readOnly={disabled}
          onBlur={() => {
            if (item.amount === '.') {
              handleAmountChange('0');
              return;
            }
            if (item.amount.startsWith('.')) {
              handleAmountChange('0' + item.amount);
              return;
            }
          }}
        />
      </label>
      <AdvancedNetworkSelect
        networks={availableNetworks}
        value={networks.find(n => n.id === item.network)}
        onChange={network => {
          setReceivingNetwork(i, network?.id ?? networks[0].id);
        }}
        onSwitch={() => {
          setReceivingNetwork(i, item.network);
        }}
        disabled={disabled}
      >
        {network => (
          <PopoverTrigger
            className="advanced-network-select-trigger third-input"
            disabled={disabled}
          >
            {network ? (
              <>
                <span className="advanced-network-select-trigger-icon">
                  <img src={BASE_URL + network?.network_image_url} alt="" />
                </span>
                <span className="advanced-network-select-trigger-label">
                  {network?.name}
                </span>
              </>
            ) : (
              <span className="advanced-network-select-trigger-placeholder">
                Choose network
              </span>
            )}
            {!disabled && <ArrowDownIcon />}
          </PopoverTrigger>
        )}
      </AdvancedNetworkSelect>
      {!disabled && width > 746 && (
        <button
          className="select-receiving--delete"
          onClick={() => removeReceiving(i)}
        >
          <Trashcan />
        </button>
      )}
    </>
  );
}
