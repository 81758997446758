import { PopoverClose } from '@radix-ui/react-popover';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import { useMemo } from 'react';
import { useAccount as useEVMAccount } from 'wagmi';
import { useShallow } from 'zustand/react/shallow';
import { BASE_URL } from '@/Config/ApiConfig';
import { Image } from '@/components/Image';
import { LoaderSmall } from '@/components/LoaderSmall';
import { ROUND_TOKENS } from '@/constants/tokens';
import { useBalance } from '@/hooks/useBalance';
import { useWalletStore } from '@/stores/wallet.store';
import { ICurrency, INetwork } from '@/types/apiTypes';
import { getMinimalDecimalNumber } from '@/utils/numbers';
import { useAdvancedWalletStore } from '@/stores/advanced.store';
interface NetworkItemProps {
  network: INetwork;
  variant?: 'from' | 'to';
  onChange: (network: INetwork) => void;
}

export function NetworkItem({ network, variant, onChange }: NetworkItemProps) {
  const { isConnected: isEVMConnected } = useEVMAccount();
  const [networkFrom, networksTo, currencyFrom] = useAdvancedWalletStore(
    useShallow(s => [
      s.networkFrom,
      s.receiving.map(item => item.network),
      s.currencyFrom,
    ])
  );
  const { allCurrencyPairs } = useWalletStore();
  const [isTokenInNetwork, currency] = useMemo(() => {
    let isTokenInNetwork = false;
    let symbol = currencyFrom;

    allCurrencyPairs.forEach(currency => {
      if (currency.contract.network.id === network.id) {
        isTokenInNetwork = true;
        symbol = currency;
      }
    });
    return [
      isTokenInNetwork || currencyFrom?.contract.network.id === network.id,
      symbol as ICurrency | undefined,
    ];
  }, [allCurrencyPairs, network.id, currencyFrom]);

  const { balance, loading } = useBalance(currency);

  const formattedBalance = useMemo(() => {
    if (!isEVMConnected) return '0.00';

    const shouldRoundToken = ROUND_TOKENS.includes(
      currency?.symbol.toLowerCase() ?? ''
    );

    const formatted = +balance <= 0 ? 0 : getMinimalDecimalNumber(balance);

    if (+formatted <= 0) return '0.00';

    if (shouldRoundToken) {
      return +formatted < 0.0001 ? '0.00' : formatted;
    }

    return +formatted < 0.000001 ? '0.00' : formatted;
  }, [balance, currency?.symbol, isEVMConnected]);

  const isDisabled = useMemo(() => {
    if (variant === 'from') {
      return networksTo.some(item => item === network.id);
    }

    return network.id === networkFrom?.id;
  }, [networksTo, network.id, networkFrom?.id, variant]);

  return (
    <motion.div
      initial={{
        opacity: 0,
        filter: 'blur(10px)',
      }}
      variants={{
        animate: {
          opacity: 1,
          filter: 'blur(0px)',
        },
      }}
    >
      <PopoverClose
        onClick={() => {
          if (isDisabled) return;
          onChange(network);
        }}
        className={clsx(
          'd-flex flex-row align-items-center w-100 cursor p-0 link-offset-3-hover advanced-network-select-menu-item',
          (!network.active || isDisabled) && 'disabled-network-wrapper'
        )}
      >
        <span
          className={clsx(
            'w-100 d-flex flex-row align-items-center cursor',
            (!network.active || isDisabled) && 'disabled-network'
          )}
        >
          <span className="d-flex coinBtn border-0 p-1 me-3 btn-20">
            <Image
              withSmoothLoading
              src={BASE_URL + network.network_image_url}
              alt="token"
              className="icon-16"
            />
          </span>
          {network.name}
          {!network.active && variant === 'from' && (
            <span className="network-balance ms-auto disabled-network-description">
              Temporary not available
            </span>
          )}
          {isTokenInNetwork &&
            isEVMConnected &&
            network.active &&
            variant === 'from' && (
              <span
                className={clsx(
                  'network-balance ms-auto me-3',
                  +balance <= 0 && 'network-balance-disabled',
                  loading && 'network-balance-loading'
                )}
              >
                {loading ? (
                  <LoaderSmall
                    width={16}
                    height={16}
                    className="network-balance-loader"
                  />
                ) : (
                  formattedBalance
                )}{' '}
                {currency?.symbol}
              </span>
            )}
        </span>
      </PopoverClose>
    </motion.div>
  );
}
