import clsx from 'clsx';
import { useMemo } from 'react';
import { useShallow } from 'zustand/react/shallow';
import { MotionProps, motion } from 'framer-motion';

import { useAdvancedWalletStore } from '@/stores/advanced.store';
import { BASE_URL } from '@/Config/ApiConfig';
import { INetwork } from '@/types/apiTypes';

interface NetworkFormItemProps extends MotionProps {
  network: INetwork;
  onNetworkChange: (network: INetwork) => void;
  networkFrom?: INetwork;
  className?: string;
  i: number;
  arr: INetwork[];
}

export function NetworkFromItem({
  network,
  networkFrom,
  onNetworkChange,
  className,
  i,
  arr,
  ...props
}: NetworkFormItemProps) {
  const receiving = useAdvancedWalletStore(useShallow(s => s.receiving));

  const isSelectedAsDestination = useMemo(() => {
    return receiving.some(item => item.network === network.id);
  }, [receiving, network.id]);

  return (
    <motion.button
      initial={{ x: -4 * i, zIndex: arr.length - i }}
      whileHover={{ x: i === 0 ? 0 : -4 * (i - 1) }}
      aria-label={`Select ${network.name}`}
      onClick={() => onNetworkChange(network)}
      className={clsx(
        'd-flex advancedCoinBtn border-0',
        (!network.active || isSelectedAsDestination) &&
          'disabled-network-circle',
        className
      )}
      disabled={!network.active || isSelectedAsDestination}
      {...props}
    >
      <img
        src={BASE_URL + network.network_image_url}
        alt="token"
        className={clsx(network.id === networkFrom?.id && 'ms1', 'networkIcon')}
      />
    </motion.button>
  );
}
