import {
  PopoverClose,
  PopoverContent,
  PopoverPortal,
  PopoverTrigger,
  Root,
} from '@radix-ui/react-popover';
import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import { useState } from 'react';

import { ICurrency, ICurrencyWithPairs } from '@/types/apiTypes';
import { TriangleDown } from '@/Assets/Icons/TriangleDown';
import { BASE_URL } from '@/Config/ApiConfig';
import './styles.css';

type CurrencyTypes = ICurrency | ICurrencyWithPairs;

interface CurrencySelectProps<T extends CurrencyTypes> {
  options: T[];
  value?: T;
  onChange?: (currency: T) => void;
  isInModal?: boolean;
  align?: 'center' | 'end' | 'start';
}

export function CurrencySelect<T extends CurrencyTypes>({
  value,
  onChange,
  options,
  isInModal = false,
  align,
}: CurrencySelectProps<T>) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="currency-select w-100">
      <Root open={isOpen} onOpenChange={setIsOpen} modal={isInModal}>
        <PopoverTrigger className="currency-select--trigger">
          <div className="currency-select--trigger--content">
            {value?.image_url && (
              <div className="currency-select--image">
                <img
                  src={BASE_URL + value?.image_url}
                  alt={`${value?.symbol} favicon`}
                />
              </div>
            )}
            {value?.symbol || 'Select currency'}
          </div>
          <TriangleDown className="currency-selected--curret-down" />
        </PopoverTrigger>
        <AnimatePresence key={value?.id}>
          {isOpen && (
            <PopoverPortal>
              <PopoverContent
                align={align ?? 'end'}
                sideOffset={27}
                alignOffset={-20}
                avoidCollisions={false}
                asChild
              >
                <motion.div
                  initial={{ opacity: 0, y: 10 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: 10 }}
                  className="currency-select--menu"
                >
                  {options.map(option => (
                    <PopoverClose
                      key={option.id}
                      className={clsx(
                        'currency-select--menu-item',
                        option.id === value?.id && 'active'
                      )}
                      onClick={() => onChange?.(option)}
                      disabled={!option.active}
                    >
                      <div className="currency-select--image">
                        <img
                          src={BASE_URL + option.image_url}
                          alt={`${option.symbol} favicon`}
                        />
                      </div>
                      {option.symbol}
                      {option.active && (
                        <div className="currency-select--menu-indicator" />
                      )}
                    </PopoverClose>
                  ))}
                </motion.div>
              </PopoverContent>
            </PopoverPortal>
          )}
        </AnimatePresence>
      </Root>
    </div>
  );
}
