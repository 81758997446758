import { ArrowRight } from '@/Assets/Icons/ArrowRight';
import { ReactNode } from 'react';
import { Link } from 'react-router-dom';

interface OtherItemProps {
  backgroundSrc?: string;
  href: string;
  children?: ReactNode;
  target?: string;
  showExternalIcon?: boolean;
}

export function OtherItem({
  backgroundSrc,
  href,
  target,
  children,
  showExternalIcon = true,
}: OtherItemProps) {
  return (
    <Link
      to={href}
      target={target}
      className="notifications-menu--other-item"
      style={{
        backgroundImage: `url(${backgroundSrc})`,
      }}
    >
      {children}
      {showExternalIcon && (
        <div className="notifications-menu--other-item--external">
          <ArrowRight width={14} height={14} style={{ rotate: '-45deg' }} />
        </div>
      )}
    </Link>
  );
}
