import { tronWeb } from '@/tron/providers/TronProvider';

export const getTronBalance = async (
  account: string,
  rpc_url?: string,
  currencyAddress?: string,
  currencyDecimals?: number
) => {
  if (!rpc_url || !currencyAddress || !currencyDecimals) {
    return '0';
  }
  let balance = 0;
  let decimals = 6;

  tronWeb.setAddress(account);

  if (currencyAddress) {
    const token = await tronWeb?.contract()?.at(currencyAddress);
    balance = await token.balanceOf(account).call();
    decimals = await token.decimals().call();
  } else {
    balance = await tronWeb.trx.getBalance(account);
  }
  return (+balance.toString() / 10 ** decimals).toString();
};
