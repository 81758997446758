import clsx from 'clsx';
import { motion, Transition } from 'framer-motion';
import React, { useEffect, useRef, useState } from 'react';
import { useWindowSize } from 'usehooks-ts';

interface AnimateHeightChangeProps {
  children: React.ReactNode;
  transition?: Transition;
  className?: string;
  ignoreForMobile?: boolean;
}

export const AnimateHeightChange: React.FC<AnimateHeightChangeProps> = ({
  children,
  className,
  transition,
  ignoreForMobile = true,
}) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [height, setHeight] = useState<number | 'auto'>('auto');
  const { width } = useWindowSize();

  useEffect(() => {
    if (containerRef.current) {
      const resizeObserver = new ResizeObserver(entries => {
        // We only have one entry, so we can use entries[0].
        const observedHeight = entries[0].contentRect.height;
        setHeight(observedHeight);
      });

      resizeObserver.observe(containerRef.current);

      return () => {
        // Cleanup the observer when the component is unmounted
        resizeObserver.disconnect();
      };
    }
  }, []);

  return (
    <motion.div
      className={clsx(className, 'overflow-hidden')}
      style={{ height: width <= 500 && ignoreForMobile ? 'auto' : height }}
      animate={{ height: width <= 500 && ignoreForMobile ? 'auto' : height }}
      transition={transition ?? { duration: 0.1 }}
    >
      <div ref={containerRef}>{children}</div>
    </motion.div>
  );
};
