import tronIcon from '@/Assets/images/wallets/tron.png';
import { AdapterName } from '@tronweb3/tronwallet-abstract-adapter';
import { useWallet } from '@tronweb3/tronwallet-adapter-react-hooks';
import {
  BitgetWalletAdapterName,
  OkxWalletAdapterName,
  TokenPocketAdapterName,
  TronLinkAdapterName,
  WalletConnectWalletName,
} from '@tronweb3/tronwallet-adapters';

export const TRON_ICONS: Record<AdapterName<string>, string> = {
  [TronLinkAdapterName]: 'tronLink',
  [OkxWalletAdapterName]: 'okx',
  [BitgetWalletAdapterName]: 'bitget',
  [TokenPocketAdapterName]: 'tokenPocket',
  [WalletConnectWalletName]: 'walletConnect',
};

export const useConnectedTronIcon = () => {
  const { wallet } = useWallet();

  return wallet && TRON_ICONS[wallet.adapter.name]
    ? require(`@/Assets/images/wallets/${TRON_ICONS[wallet.adapter.name]}.png`)
    : tronIcon;
};
