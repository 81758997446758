import { AxiosError } from 'axios';
import { useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { phaseService } from '@/services/phaseApi';
import { ArrowBackIcon } from '@/Assets/Icons/arrowBack';
import { ArrowDown } from '@/Assets/Icons/arrowDown';
import { BASE_URL } from '@/Config/ApiConfig';
import { PROGRESS_PAGE } from '@/constants/routes.constants';
import { useNotify } from '@/hooks/useToast';
import { useWithdraw } from '@/hooks/useWithdraw';
import { formatAddress } from '@/pages/PhasePage/PhaseCard/WalletMenu';
import { api } from '@/services/api';
import { useAppStore } from '@/stores/app.store';
import { getPrice, usePriceUpdater } from '@/stores/price_updater.store';
import { toFixed } from '@/utils/numbers';
import { useShallow } from 'zustand/react/shallow';
import { FeeDetails } from './FeeDetails';
import { NetworkInfo } from './networkInfo';
import { useWalletFrom } from '@/hooks/useWalletFrom';
import { useConnectedIconByNetworkType } from '@/hooks/useNetworkConnectedIcon';

import './TransferInfoPage.css';
import { SubmitButton } from '../../SubmitButton';

function TransferInfoForm({ closeConfirm }: { closeConfirm: () => void }) {
  const navigate = useNavigate();

  const { notify } = useNotify();

  const setIsWaitingForConfirmation = useAppStore(
    s => s.setIsWaitingForConfirmation
  );

  const [amountFrom, amountTo, walletTo, setIsCancelled] = useAppStore(
    useShallow(s => [s.amountFrom, s.amountTo, s.walletTo, s.setIsCancelled])
  );
  const [networkFrom, networkTo] = useAppStore(
    useShallow(s => [s.networkFrom, s.networkTo])
  );
  const [currencyFrom, currencyTo] = useAppStore(
    useShallow(s => [s.currencyFrom, s.currencyTo])
  );
  const [isDisabled, setIsDisabled] = useAppStore(
    useShallow(s => [s.isDisabled, s.setIsDisabled])
  );
  const [receiver, transactionFee] = useAppStore(
    useShallow(s => [s.receiver, s.transactionFee])
  );

  const { withdraw } = useWithdraw();
  const { prices } = usePriceUpdater();

  const amountFromUSD = useMemo(() => {
    if (!currencyFrom?.symbol) return 0;

    const currencyFromPrice = getPrice(currencyFrom?.symbol, prices);

    const amountFromUSD = +amountFrom * currencyFromPrice.usd;
    return amountFromUSD;
  }, [prices, currencyFrom?.symbol, amountFrom]);

  const amountToUSD = useMemo(() => {
    if (!currencyTo?.symbol) return 0;

    const currencyToPrice = getPrice(currencyTo?.symbol, prices);

    const amountToUSD = +amountTo * currencyToPrice.usd;
    return amountToUSD;
  }, [prices, currencyTo?.symbol, amountTo]);

  useEffect(() => {
    if (currencyFrom && currencyTo) api.getReceiver(currencyFrom, currencyTo);
  }, [currencyFrom, currencyTo]);

  const withdrawCurrencyHelper = useCallback(
    async (wallet_sender: string) => {
      let order;
      try {
        setIsDisabled(true);

        if (!currencyFrom || !currencyTo) {
          return;
        }

        order = await api.sendOrder(
          currencyFrom,
          currencyTo,
          amountFrom,
          wallet_sender,
          walletTo
        );

        if (!order) {
          throw new Error('Failed to create order');
        }

        navigate(`${PROGRESS_PAGE}/${order.id}`);

        setIsWaitingForConfirmation(true);

        const result = await withdraw({
          sendedAmount: +amountFrom,
          sendedCurrency: currencyFrom,
          network: networkFrom,
          walletReceiver: receiver,
        });

        if (!result) {
          setIsCancelled(true);
        }
      } catch (error) {
        if (
          (error as Error).message === 'ACTION_REJECTED' &&
          order &&
          networkFrom
        ) {
          api
            .closeOrder(order.id, networkFrom)
            .catch(() => console.warn('Failed to close order'));
        } else if (order && networkFrom) {
          api.setOrderAsFailed(order.id, networkFrom).catch(() => {
            console.warn('Failed to set order as failed');
          });
        }

        if (error instanceof AxiosError) {
          const defaultMessage =
            'Something went wrong. Please try again later.';
          switch (error.response?.status) {
            case 503:
              return notify({
                title: 'Temporary transfer limit',
                meassage: 'Destination network will become available shortly',
                type: 'error',
              });
            default:
              return notify({
                meassage: error?.response?.data?.message ?? defaultMessage,
                type: 'error',
              });
          }
        }
        notify({
          meassage: 'Something went wrong. Please try again later.',
          type: 'error',
        });
        setIsCancelled(true);
      } finally {
        setIsWaitingForConfirmation(false);
        setIsDisabled(false);
      }
    },
    [
      amountFrom,
      currencyFrom,
      networkFrom,
      receiver,
      currencyTo,
      walletTo,
      withdraw,
      navigate,
      setIsCancelled,
      setIsDisabled,
      setIsWaitingForConfirmation,
      notify,
    ]
  );

  const { walletFrom } = useWalletFrom(networkFrom);
  const connectedWalletToIcon = useConnectedIconByNetworkType(
    networkTo?.network_type
  );

  useEffect(() => {
    if (walletFrom && !!currencyTo?.contract.network.id) {
      phaseService.getUserNFT(walletFrom, currencyTo.contract.network.id);
    }
  }, [walletFrom, currencyTo?.contract.network.id]);

  const onClickSendTransactionHandler = useCallback(() => {
    if (walletFrom) {
      withdrawCurrencyHelper(walletFrom);
    }
  }, [walletFrom, withdrawCurrencyHelper]);

  const totalFeeInUSD = useMemo(() => {
    if (!currencyTo?.symbol) return 0;

    const price = getPrice(currencyTo?.symbol, prices);

    return toFixed(price.usd * +(transactionFee?.full_fee ?? 0), 2);
  }, [transactionFee, prices, currencyTo]);

  const minutes = 1;

  return (
    <div className="w-100">
      <div className="formBody text-light mobile-margin-bottom">
        <div className="transferInfoTitle">Your Transfer Details</div>
        <div className="transactionInfoDableDiv">
          <div className="infoDiv ">
            <NetworkInfo
              netwSrc={BASE_URL + networkFrom?.network_image_url}
              netw={networkFrom?.name ?? ''}
              wallet={walletFrom ?? ''}
              amount={amountFrom}
              amountUsd={amountFromUSD.toString()}
              coinSymbol={currencyFrom?.symbol ?? ''}
            />
          </div>

          <div className="circle .circle-bg">
            <div className="transactionInfoDableDivCircle">
              <ArrowDown className="arrowDownIcon" />
            </div>
          </div>

          <div className="infoDiv">
            <NetworkInfo
              netwSrc={BASE_URL + networkTo?.network_image_url}
              netw={networkTo?.name ?? ''}
              wallet={walletTo}
              amount={amountTo}
              amountUsd={amountToUSD.toString()}
              coinSymbol={currencyTo?.symbol ?? ''}
            />
          </div>
        </div>
        <div className="transfer-info--label mt-4">
          <span>Amount You Sent</span>
          <span className="ms-auto text-white">
            ${toFixed(amountFromUSD, 2)}
          </span>
        </div>

        <div className="infoDiv mb-3">
          <div className="networkInfoLeftDiv">
            <div className="imgDiv20 me-3">
              <img
                className="coinImg"
                src={BASE_URL + currencyFrom?.image_url}
                alt="network icon"
              />
            </div>
            <div className="infoText transfer-info--value">
              {amountFrom} {currencyFrom?.symbol}
            </div>
          </div>
        </div>

        <div className="transfer-info--label">
          <span>You receive</span>
          <span className="ms-auto text-white">${toFixed(amountToUSD, 2)}</span>
        </div>
        <div className="mb-4">
          <div className="infoDiv rounded-bottom-0">
            <div className="networkInfoLeftDiv">
              <div className="imgDiv20 me-3">
                <img
                  className="coinImg"
                  src={BASE_URL + currencyTo?.image_url}
                  alt="network icon"
                />
              </div>
              <div className="infoText transfer-info--value">
                {amountTo || 0} {currencyTo?.symbol}
              </div>
            </div>
            <div className="d-flex flex-column receiveShortBlock ms-auto">
              <p className="text-white bold">
                In less than {minutes} minute{minutes > 1 ? 's' : ''}
              </p>
              <span className="fw-medium blanco">
                ${totalFeeInUSD} Total Fee
              </span>{' '}
            </div>
          </div>
          <FeeDetails />
        </div>

        <div className="transfer-info--label-bold">Receiving address</div>
        <div className="infoDiv">
          <div className="networkInfoLeftDiv">
            <div className="imgDiv20 me-3">
              <img src={connectedWalletToIcon} alt="network icon" />
            </div>
            <div className="infoText fs-6 fw-bold">
              {formatAddress(walletTo, 4, 3)}
            </div>
          </div>
        </div>

        <SubmitButton
          title="Send Transfer"
          networkFrom={networkFrom}
          currencyFrom={currencyFrom}
          isDisabled={isDisabled}
          onSubmit={onClickSendTransactionHandler}
          isConfirm
        />

        <div className="center">
          <button
            onClick={closeConfirm}
            type="button"
            className="backBtn ms-auto me-auto mt-4"
          >
            <div className="d-flex">
              <ArrowBackIcon className="arroBack" />
              <div className="ms-2">Back</div>
            </div>
          </button>
        </div>
      </div>
    </div>
  );
}

export default TransferInfoForm;
