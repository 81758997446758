const TronWeb = require('tronweb/dist/TronWeb');

export const isValidTronAddress = (address?: string) => {
  try {
    if (!address) return false;

    return TronWeb.isAddress(address);
  } catch (e) {
    return false;
  }
};
