import { useWallet } from '@tronweb3/tronwallet-adapter-react-hooks';
import { useEffect, useState } from 'react';

export function useTronChainId() {
  const { wallet: tronWallet, address } = useWallet();
  const [chainId, setChainId] = useState<string | undefined>();

  useEffect(() => {
    const fetch = async () => {
      if (!!tronWallet && address && 'network' in tronWallet?.adapter) {
        const network = await (tronWallet?.adapter as any).network();

        setChainId(network.chainId as string);
      }
    };
    fetch();
  }, [tronWallet, address]);

  return chainId;
}
