import { useWallet } from '@solana/wallet-adapter-react';
import solana from '@/Assets/images/wallets/solana.png';
import { getSolanaWalletIcon } from '@/solana/getSolanaWalletIcon';

export const useSolanaConnectedIcon = () => {
  const { wallet } = useWallet();

  return getSolanaWalletIcon(
    wallet?.adapter.name || '',
    wallet?.adapter.icon || solana
  );
};
