import { WalletProvider } from '@tronweb3/tronwallet-adapter-react-hooks';
import {
  BitKeepAdapter,
  OkxWalletAdapter,
  TokenPocketAdapter,
  TronLinkAdapter,
  WalletConnectAdapter,
} from '@tronweb3/tronwallet-adapters';
import { NetworkType as TronNetworkType } from '@tronweb3/tronwallet-abstract-adapter';
import { ReactNode, useMemo } from 'react';
import { WALLET_CONNECT_PROJECT_ID } from '@/providers/rainbow';
const TronWeb = require('tronweb/dist/TronWeb');

interface TronProviderProps {
  children: ReactNode;
}

export const tronWeb = new TronWeb({
  fullHost: 'https://api.trongrid.io',
  privateKey: '01', // Public key for read-only access
});

export function TronProvider({ children }: TronProviderProps) {
  const adapters = useMemo(
    () => [
      new TronLinkAdapter(),
      new OkxWalletAdapter(),
      new BitKeepAdapter(),
      new TokenPocketAdapter(),
      new WalletConnectAdapter({
        network: TronNetworkType.Mainnet,
        options: {
          relayUrl: 'wss://relay.walletconnect.com',
          projectId: WALLET_CONNECT_PROJECT_ID,
          metadata: {
            name: 'RetroBridge',
            description: 'RetroBridge',
            url: 'https://app.retrobridge.io/',
            icons: ['https://retrobridge.io/favicon.png'],
          },
        },
        web3ModalConfig: {
          themeMode: 'dark',
          themeVariables: {
            '--w3m-z-index': 1000,
          } as any,
        },
      }),
    ],
    []
  );

  return <WalletProvider adapters={adapters}>{children}</WalletProvider>;
}
