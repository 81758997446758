import { NetworkTypes } from '@/providers/web3Provider';
import { isValidEvmAddress } from '@/evm/utils';
import { isValidSolAddress } from '@/solana/utils/isValidSolanaAddress';
import { isValidStarknetAddress } from '@/starknet/utils/isValidAddress';
import { isValidTonAddress } from '@/ton/utils';
import { isValidTronAddress } from '@/tron/utils';

export const isValidAddress = (
  address: string | undefined,
  network?: NetworkTypes
) => {
  if (!address) return false;

  switch (network) {
    case NetworkTypes.STARKNET:
      return isValidStarknetAddress(address);
    case NetworkTypes.TON:
      return isValidTonAddress(address);
    case NetworkTypes.SOLANA:
      return isValidSolAddress(address);
    case NetworkTypes.TRON:
      return isValidTronAddress(address);
    case NetworkTypes.EVM:
    case NetworkTypes.ZK_SYNC_ERA:
      return isValidEvmAddress(address);
    default:
      return (
        isValidEvmAddress(address) ||
        isValidStarknetAddress(address) ||
        isValidSolAddress(address) ||
        isValidTonAddress(address) ||
        isValidTronAddress(address)
      );
  }
};
